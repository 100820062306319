
const COMPANY_NAME = "Blue Chip Market Research LLC";
const APPLICATION_NAME = "The Market Toolkit";
const APPLICATION_NAME_UPPER = "THE MARKET TOOLKIT";
const WEBSITE_URL = "https://www.themarkettoolkit.com";
const RUNNING_WEBSITE_URL = window.location.hostname === "localhost" ? "http://localhost:8888" : "https://www.themarkettoolkit.com";
const GOOGLE_ANALYTICS_TRACKING_ID_LOCALHOST = "G-FEW566SRPN";
const GOOGLE_ANALYTICS_TRACKING_ID_PRODUCTION = "G-T4MW75Y7F3";
const IS_LOCALHOST = window.location.hostname === "localhost";

const GET_TEMPLATES_TEXT = `All tools are available for download from the desktop ` +
  `application's Tools, Download menu option for licensed users. ` +
  `The first three tools (#1 - #3) are available to all users.`;
const SEE_COMMENTS = "See code comments for explanations of specific lines of code.";

const GENERIC_ERROR_MESSAGE = "Something unexpected happened. Please try again. " +
  "If the error continues to occur, reach out to us at support@themarkettoolkit.com."

export {
  COMPANY_NAME,
  APPLICATION_NAME,
  APPLICATION_NAME_UPPER,
  WEBSITE_URL,
  RUNNING_WEBSITE_URL,
  GOOGLE_ANALYTICS_TRACKING_ID_LOCALHOST,
  GOOGLE_ANALYTICS_TRACKING_ID_PRODUCTION,
  IS_LOCALHOST,
  GET_TEMPLATES_TEXT,
  SEE_COMMENTS,
  GENERIC_ERROR_MESSAGE
}