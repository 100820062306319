import { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { sendEmailVerification, AuthErrorCodes } from "firebase/auth";
import { auth } from "../../firebase-config";
import { useAuthValue } from '../../AuthContext'
import { APPLICATION_NAME } from "../../Constants";
import DisableAfterClickButton from "../../components/DisableAfterClickButton";

export default function MustVerifyEmail() {

  const [message, setMessage] = useState(null);

  const { currentUser } = useAuthValue()

  const resendEmailVerification = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setMessage(null);
    }
    catch (error) {
      switch (error.code) {
        case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
          setMessage("Too many attempts. Please try again later.");
          break;
        default:
          setMessage(error.message);
      }
    };
  }

  return (
    <main className="must-verify-email">
      <Helmet>
        <title>{APPLICATION_NAME} | Must Verify Email</title>
        <meta name="description" content="Please verify your email address." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/must-verify-email" />
      </Helmet>
      <h3 className="mb-3">Verify your Email Address</h3>
      <p>
        A verification email has been sent to: <strong>{currentUser?.email}</strong>
      </p>
      <p>
        Follow the instructions in the email to verify your account.<br />
        Be sure to check the junk folder if the email isn't in your primary Inbox.
      </p>
      <p className="fw-bold">{message}</p>
      <p className="mb-3">
        <DisableAfterClickButton text="Resend Email" duration="500" handleClick={resendEmailVerification} />
      </p>
      <p>
        After you have verified your email address, please <Link to="/sign-in">Sign In</Link> again.
      </p>
    </main>
  );
}