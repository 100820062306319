import dayjs from 'dayjs';

export default function LastUpdated({ updated }) {
  return (
    updated ?
      <p className="text-muted text-small">
        <small>Last Updated: <time dateTime={updated}>{dayjs(updated).format('MMMM D, YYYY')}</time>.</small>
      </p>
      : <></>
  );
}