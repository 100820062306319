import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { apiUpdateCard } from "../../apiService";
import { APPLICATION_NAME } from "../../Constants";
import CardForm from "../../components/licensing/CardForm";

export default function UpdateCard() {

  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return '';
  }

  const handleClick = async (cardElement, name) => {

    const sid = searchParams.get('sid');

    try {

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: name,
        }
      });

      //card details not entered correctly - ex: expired date
      if (result.error) throw result.error;
      await apiUpdateCard(sid, result.paymentMethod.id);
      navigate("/licenses");
    }
    catch (error) {
      setMessage(error.message);
    }
  }

  return (
    <main className="update-card">
      <Helmet>
        <title>{APPLICATION_NAME} | Update Card</title>
        <meta name="description" content="Update your credit card information." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/update-card" />
      </Helmet>
      <h3 className="mb-3">Update Credit Card</h3>
      <p className="text-danger">{message ?? ""}</p>
      <CardForm buttonText="Update" onClick={handleClick} />
    </main>
  );
}