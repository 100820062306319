import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";
import { apiGetToolsList } from "../apiService";
import ToolContent from "../components/ToolContent";
import LastUpdated from "../components/LastUpdated";

export default function Tools() {

  const [allTools, setAllTools] = useState([]);
  const [tools, setTools] = useState([]);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    const getData = async () => {
      const _tools = await apiGetToolsList();
      setAllTools(_tools);
      setTools(_tools.slice());
    }
    getData();
  }, []);

  const filter = (e) => {
    const text = e.target.value;
    setFilterText(text)
    if (!text) {
      setTools(allTools.slice());
    }
    else {
      const textLowerCase = text.toLowerCase();
      const _tools = allTools.filter(x => x.title.toLowerCase().includes(textLowerCase));
      setTools(_tools);
    }
  }

  return (
    <main className="tools">
      <Helmet>
        <title>{APPLICATION_NAME} | Tools</title>
        <meta name="description" content="View descriptions of over 35 different tools, each providing unique insight into the financial markets." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/tools" />
      </Helmet>
      <header>
        <h1 className="page-title">Tools</h1>
        <p className="text-muted mb-3">
          Tools provide insight into stocks, ETFs, indexes or macroeconomic indicators.
          They are downloadable from within the desktop application.
        </p>
      </header>
      <form className="mb-0 col-12">
        <input type="text" className="form-control" placeholder="Enter a keyword or phrase in this text box to filter the list of tools."
          value={filterText} onChange={filter} />
      </form>
      {
        tools.map((tool, i) => {
          return <article className="col-12" key={i}>
            <h5 id={tool.id}>{tool.title}</h5>
            <ToolContent tool={tool} full={false} />
            <p><Link to={`/tool/${tool.path}`}>View Details</Link></p>
            <LastUpdated updated={tool?.updated} />
          </article>
        })
      }
    </main>
  );
}