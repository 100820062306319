import { useEffect } from "react";
import ReactGA from "react-ga4";
import { IS_LOCALHOST, GOOGLE_ANALYTICS_TRACKING_ID_LOCALHOST, GOOGLE_ANALYTICS_TRACKING_ID_PRODUCTION } from "./Constants";

const usePageTracking = () => {

  useEffect(() => {
    const trackingId = IS_LOCALHOST
      ? GOOGLE_ANALYTICS_TRACKING_ID_LOCALHOST
      : GOOGLE_ANALYTICS_TRACKING_ID_PRODUCTION;
    ReactGA.initialize(trackingId, {
      gaOptions: {
        debug_mode: false
      }
    });
  }, []);

};

const trackAction = (action, actionDetail, params) => {
  if (process.env.REACT_APP_GA_TRACKING_ENABLED === 'TRUE') {
    params ??= {};
    params.app = "Website";
    params.action = action;
    params.action_detail = actionDetail;
    ReactGA.event(action, params);
  }
};

export {
  usePageTracking,
  trackAction
};