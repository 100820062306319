import { Link, useNavigate } from "react-router-dom";
import { useAuthValue } from '../AuthContext';
import HeaderNavLink from "./HeaderNavLink";
import { signOut } from "firebase/auth";
import { auth } from "../firebase-config";

function MenuItem({ to, text }) {
  return (
    <li className="nav-item">
      <HeaderNavLink to={to}>{text}</HeaderNavLink>
    </li>
  );
}

function DarkMenuItem({ to, text, ...props }) {
  return (
    <li>
      <Link className="dropdown-item nav-link text-dark" to={to} {...props}>{text}</Link>
    </li>
  );
}

function AccountMenu({ currentUser }) {

  const navigate = useNavigate()

  const handleSignOut = async (e) => {
    e.preventDefault();
    await signOut(auth);
    navigate("/sign-in");
  }

  return (currentUser
    ?
    <li className="nav-item dropdown">
      <a className="nav-link text-light dropdown-toggle" href="/#" id="account" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Account
      </a>
      <ul className="dropdown-menu" aria-labelledby="account">
        <DarkMenuItem to="/profile" text="Profile" />
        <DarkMenuItem to="/licenses" text="Licenses" />
        <DarkMenuItem to="/#" text="Sign Out" onClick={handleSignOut} />
      </ul>
    </li>
    : <MenuItem to="sign-in" text="Sign In" />
  );
}

export default function Header() {

  const { currentUser } = useAuthValue();

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light header-nav">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="/branding/512x512.png" alt="" width="32" height="32" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <MenuItem to="/" text="Home" />
              <MenuItem to="/tools" text="Tools" />
              <MenuItem to="/pricing" text="Pricing" />
              <MenuItem to="/faq" text="FAQ" />
              <MenuItem to="/contact-us" text="Contact Us" />
              <MenuItem to="/quickstart" text="Quickstart" />
              <MenuItem to="/download" text="Download" />
              {
                /*
                <MenuItem to="/blog" text="Blog" />
                */
              }
              <AccountMenu currentUser={currentUser} />
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
