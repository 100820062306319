import { useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { applyActionCode, verifyPasswordResetCode, confirmPasswordReset, checkActionCode, signOut, AuthErrorCodes } from "firebase/auth";
import { auth } from "../../firebase-config";
import { useAuthValue } from "../../AuthContext";
import { apiUpdateEmail } from "../../apiService";
import { GENERIC_ERROR_MESSAGE } from "../../Constants";
import DisableAfterClickButton from "../../components/DisableAfterClickButton";

//http://localhost:8888/auth?mode=verifyEmail&oobCode=Wwof1GFoeZkmOXTVnJyuP6QOGRR5SRrByVT9JuTzNNMAAAGDo7fI-A&apiKey=AIzaSyCQzXBOaVf2BPE9iuKKX7XAa5Y6eOsqvag&lang=en
//http://localhost:8888/auth?apiKey=AIzaSyCQzXBOaVf2BPE9iuKKX7XAa5Y6eOsqvag&mode=resetPassword&oobCode=zhtsYd-mAfrP7VncKD-jfgpVDhzEEU4SxQNYKJaOWQAAAAGDpDczRw&continueUrl=http://localhost:8888/sign-in&lang=en

export default function AuthHandler() {

  const { currentUser } = useAuthValue();

  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState(null);
  const [oobCode, setOobCode] = useState(null);
  const [loadError, setLoadError] = useState(null);

  //reset password
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')
  const [resetPasswordError, setResetPasswordError] = useState(null);

  //recover email - email changed, user received email at old email, user doesn't want change
  const [oldEmail, setOldEmail] = useState(null);
  const [recoveredEmail, setRecoveredEmail] = useState(null);

  const [success, setSuccess] = useState(false);
  const [processed, setProcessed] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {

    const load = async () => {

      const _mode = searchParams.get("mode");
      const _oobCode = searchParams.get("oobCode");

      setMode(_mode);
      setOobCode(_oobCode);

      try {
        switch (_mode) {
          case 'verifyEmail':
            await applyActionCode(auth, _oobCode);
            await signOut(auth);
            navigate({
              pathname: '/',
              search: '?action=emailVerified'
            });
            break;
          case 'resetPassword':
            const email = await verifyPasswordResetCode(auth, _oobCode);
            setEmail(email);
            break;
          case 'recoverEmail':
            const info = await checkActionCode(auth, _oobCode);
            const _oldEmail = info['data']['previousEmail'];
            const _recoveredEmail = info['data']['email'];
            setOldEmail(_oldEmail);
            setRecoveredEmail(_recoveredEmail);  //the restored email address
            break;
          default:
            setLoadError("Unknown mode.");
        }
      }
      catch (error) {
        setLoadError(GENERIC_ERROR_MESSAGE);
      }
      finally {
        setProcessed(true);
      }
    }
    load();
  }, [searchParams, navigate])

  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword) {
        setResetPasswordError("Passwords do not match.");
        return;
      }
      await confirmPasswordReset(auth, oobCode, password);
      setResetPasswordError(null);
      setSuccess(true);
    }
    catch (error) {
      switch (error.code) {
        case AuthErrorCodes.WEAK_PASSWORD:
          setResetPasswordError("Password must be at least 6 characters.");
          break;
        default:
          setResetPasswordError(error.message);
      }
    };
  }

  const recoverEmail = async (e) => {
    await applyActionCode(auth, oobCode);  //changes the email address back
    await apiUpdateEmail(currentUser?.uid, oldEmail, recoveredEmail);
    setSuccess(true);
  }

  const loadErrorHtml = () => {
    return (
      <p className="alert alert-danger" role="alert">
        {loadError}
      </p>
    );
  }

  const resetPasswordFormHtml = () => {
    return (
      <section>
        <h3 className="mb-3">Reset Password</h3>
        <p className="text-danger">{resetPasswordError ?? ""}</p>
        <form className="mb-3">
          <p className="mb-3 col-6">
            <label htmlFor="email" className="form-label">Email address: </label>
            <em>{email}</em>
          </p>
          <p className="mb-3 col-6">
            <label htmlFor="password" className="form-label">Password</label>
            <input type="password" className="form-control" id="password" value={password} required
              onChange={(e) => setPassword(e.target.value)} />
            <span className="form-text">Password must be at least 6 characters.</span>
          </p>
          <p className="mb-3 col-6">
            <label htmlFor="confirm_password" className="form-label">Confirm Password</label>
            <input type="password" className="form-control" id="confirm_password" value={confirmPassword} required
              onChange={(e) => setConfirmPassword(e.target.value)} />
            <span className="form-text">Enter the password a second time.</span>
          </p>
          <DisableAfterClickButton text="Submit" duration="500" handleClick={resetPassword} />
        </form>
      </section>
    );
  }

  const resetPasswordSuccessHtml = () => {
    return (
      <p className="alert alert-success" role="alert">
        Password has been successfully reset. <Link to="/sign-in">Sign In</Link>
      </p>
    );
  }

  const recoverEmailFormHtml = () => {
    return (
      <section>
        <h3 className="mb-3">Recover Email</h3>
        <p>Recover email address: {recoveredEmail}</p>
        <DisableAfterClickButton text="Recover" duration="500" handleClick={recoverEmail} />
      </section>
    );
  }

  const recoverEmailSuccessHtml = () => {
    return (
      <p className="alert alert-success" role="alert">
        Email address has been successfully restored to {recoveredEmail}. <Link to="/">Home</Link>
      </p>
    );
  }

  return (
    <main>
      {loadError && processed ? loadErrorHtml() : <></>}
      {(mode === "resetPassword" && !loadError && !success && processed) ? resetPasswordFormHtml() : <></>}
      {(mode === "resetPassword" && success && processed) ? resetPasswordSuccessHtml() : <></>}
      {(mode === "recoverEmail" && !loadError && !success && processed) ? recoverEmailFormHtml() : <></>}
      {(mode === "recoverEmail" && success && processed) ? recoverEmailSuccessHtml() : <></>}
    </main>
  );
}