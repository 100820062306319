import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import App from "./App";
import Home from "./pages/Home";
import Tools from './pages/Tools';
import ToolDetails from './pages/ToolDetails';
import UserImports from './pages/UserImports';
import Quickstart from './pages/Quickstart';
import Download from './pages/Download';
import Pricing from './pages/Pricing';
import ContactUs from "./pages/ContactUs";
import FAQ from './pages/FAQ';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Changelog from './pages/Changelog';
import NotFound from './pages/NotFound';

import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import MustVerifyEmail from './pages/auth/MustVerifyEmail';
import ResetPassword from './pages/auth/ResetPassword';
import AuthHandler from './pages/auth/AuthHandler';
import Profile from './pages/auth/Profile';
import Licenses from './pages/licensing/Licenses';
import Checkout from './pages/licensing/Checkout';
import UpdateCard from './pages/licensing/UpdateCard';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="tools" element={<Tools />} />
        <Route path="tool/:path" element={<ToolDetails />} />
        <Route path="user-imports" element={<UserImports />} />
        <Route path="quickstart" element={<Quickstart />} />
        <Route path="download" element={<Download />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:route" element={<BlogDetails />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="changelog" element={<Changelog />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="must-verify-email" element={<MustVerifyEmail />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="auth" element={<AuthHandler />} />
        <Route path="profile" element={<AuthenticatedRoute><Profile /></AuthenticatedRoute>} />
        <Route path="licenses" element={<AuthenticatedRoute><Licenses /></AuthenticatedRoute>} />
        <Route path="checkout" element={<AuthenticatedRoute><Checkout /></AuthenticatedRoute>} />
        <Route path="update-card" element={<AuthenticatedRoute><UpdateCard /></AuthenticatedRoute>} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
