import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";
import { apiGetToolsList } from "../apiService";
import dayjs from 'dayjs';
import Screenshot from "../components/Screenshot";

export default function Home() {

  const [allTools, setAllTools] = useState([]);
  const [searchParams] = useSearchParams();
  const [actionMessage, setActionMessage] = useState(null);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {

    const getData = async () => {
      const tools = await apiGetToolsList();
      setAllTools(tools);
    }

    getData();

    const action = searchParams.get('action');
    switch (action) {
      case "emailVerified":
        setActionMessage("Email verified successfully. Please sign in again.");
        break;
      case "accountDeleted":
        setActionMessage("Account deleted successfully.");
        break;
      default:
    }

    fetch("/announcements.json")
      .then(response => response.json())
      .then(data => {
        const now = dayjs();
        const filteredData = data.filter(x => now.isAfter(dayjs(x.startDate)) && now.isBefore(dayjs(x.endDate)));
        setAnnouncements(filteredData);
      })
  }, [searchParams])

  const ToolsList = ({ tools }) => {
    return (
      <ul className="tools-list">
        {
          tools.map((tool, i) => {
            return <li key={i}>{tool.title}</li>
          })
        }
      </ul>
    )
  }

  return (
    <main className="home">
      <Helmet>
        <title>{APPLICATION_NAME}</title>
        <meta name="description" content={`${APPLICATION_NAME} has a set of tools designed to help you make better investment decisions.`} />
        <link rel="canonical" href="https://www.themarkettoolkit.com" />
      </Helmet>
      {
        actionMessage ?
          <p className="alert alert-success" role="alert">
            {actionMessage}
          </p>
          : ""
      }
      {
        announcements.length > 0 ?
          <p className="alert alert-primary" role="alert">
            <ul>
              {announcements.map(a => <li className="announcement" key={a.id}>{a.message}</li>)}
            </ul>
          </p>
          : ""
      }
      <header className="row">
        <p className="col-12">
          {APPLICATION_NAME} is a stock research application to help you make better trading and investing decisions.
          Our desktop application provides a series of tools.
          Each tool represents a theme and most have inputs for customizing calculations and outputs.
          Tables and charts are generated, providing insight beyond what is typically provided by charting applications.
          Windows and macOS are supported.
        </p>
      </header>

      <section className="row">
        <div className="col-4">
          <h5>Over 40 Tools Available</h5>
          <p>
            There are tools available for relative strength, seasonality, macroeconomic indicators,
            presidential cycles, performance around days of the month, earnings, dividends,
            market reactions to interest rate changes, and many other topics.
          </p>
          <p>
            View the current list of <Link to="/tools">Tools</Link>.
          </p>
        </div>
        <div className="col-8">
          <Screenshot subfolder="tool" name="T40.png" title="Dividend Tracker tool." />
        </div>
      </section>

      <section className="row">
        <div className="col-8">
          <Screenshot subfolder="tool" name="T1.png" title="Treasury Rates tool." />
        </div>
        <div className="col-4">
          <h5>Economic Data</h5>
          <p>
            View economic data, covering areas such as U.S. Treasury rates, inflation, and employment.
          </p>
        </div>
      </section>
      <section className="row">
        <div className="col-4">
          <h5>Analyst Expectations</h5>
          <p>
            View analyst expectations for companies, such as price targets and upgrades/downgrades.
          </p>
        </div>
        <div className="col-8">
          <Screenshot subfolder="tool" name="T8.png" title="Price Targets tool." />
        </div>
      </section>

      <section className="row">
        <div className="col-8">
          <Screenshot subfolder="tool" name="T11.png" title="Earnings History tool." />
        </div>
        <div className="col-4">
          <h5>Earnings</h5>
          <p>
            Compare revenue and EPS changes across time.
            View upcoming earnings and reactions to past earnings.
            We also have tools for viewing financial statement metrics such as free cash flow and EBITDA.
          </p>
        </div>
      </section>
      <section className="row">
        <div className="col-4">
          <h5>Performance</h5>
          <p>
            View performance by year, by month, and by date ranges with months.
            Also view performance around holidays and major market events, and in presidential cycles.
          </p>
        </div>
        <div className="col-8">
          <Screenshot subfolder="tool" name="T16.png" title="Performance By Year Tool." />
        </div>
      </section>

      <section className="row">
        <div className="col-8">
          <Screenshot subfolder="tool" name="T22.png" title="Performance Around Rate Changes tool." />
        </div>
        <div className="col-4">
          <h5>The Federal Reserve</h5>
          <p>
            Analyze market reactions to federal funds rate changes and visualize year-over-year and month-over-month
            changes to real rates.
          </p>
        </div>
      </section>
      <section className="row">
        <div className="col-4">
          <h5>ETFs</h5>
          <p>
            View weights and performances of ETF holdings.
            Also compare ETFs, highlighting shared holdings and percentages within them.
          </p>
        </div>
        <div className="col-8">
          <Screenshot subfolder="tool" name="T31.png" title="ETF Components Tool." />
        </div>
      </section>

      <section className="row">
        <div className="col-8">
          <Screenshot subfolder="tool" name="T36.png" title="Portfolio Viewer tool." />
        </div>
        <div className="col-4">
          <h5>Portfolios</h5>
          <p>
            Combine multiple portfolios and view upcoming earnings and dividends.
          </p>
        </div>
      </section>
      <section className="row">
        <div className="col-4">
          <h5>Journal</h5>
          <p>
            Build a trading journal, quickly viewing charts centered around journal dates.
            Add events, including custom events, to charts.
          </p>
        </div>
        <div className="col-8">
          <Screenshot subfolder="tool" name="T41.png" title="Journal Tool." />
        </div>
      </section>

      <section className="row">
        <div className="col-8">
          <Screenshot subfolder="tool" name="T43.png" title="Charts of earnings reactions over the past five years." />
        </div>
        <div className="col-4">
          <h5>Comparisons</h5>
          <p>
            Compare symbols in multiple ways, including performance, earnings reactions, and holdings (for ETFs).
          </p>
          <h5>Free Version Available</h5>
          <p>
            Access the first three tools for free. No login or credit card required.
            The licensed version is <strong>$15/month</strong>.
          </p>
        </div>
      </section>

      <section className="row">
        <h5 className="mb-3">Current Tools List:</h5>
        <div className="col-6">
          <ToolsList tools={allTools.slice(0, 22)} />
        </div>
        <div className="col-6">
          <ToolsList tools={allTools.slice(22)} />
        </div>
      </section>

    </main>
  );
}