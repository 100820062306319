import { useState } from "react";
import { Helmet } from "react-helmet";
import { sendPasswordResetEmail, AuthErrorCodes } from "firebase/auth";
import { auth } from "../../firebase-config";
import { APPLICATION_NAME } from "../../Constants";
import DisableAfterClickButton from "../../components/DisableAfterClickButton";

export default function ResetPassword() {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);

  const sendEmail = async (e) => {
    e.preventDefault();
    var actionCodeSettings = {
      url: `${process.env.REACT_APP_BASE_URL}/sign-in`,
      handleCodeInApp: true
    };
    try {
      await sendPasswordResetEmail(auth, email, actionCodeSettings);
      setMessage(`An email with instructions has been sent to ${email}.`);
    }
    catch (error) {
      switch (error.code) {
        case AuthErrorCodes.USER_DELETED:
          setMessage("Email address not found.");
          break;
        default:
          setMessage(error.message);
      }
    };
  }

  return (
    <main className="reset-password">
      <Helmet>
        <title>{APPLICATION_NAME} | Reset Password</title>
        <meta name="description" content="Reset your password." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/reset-password" />
      </Helmet>
      <h3 className="mb-3">Reset Password</h3>
      <p>{message ?? ""}</p>
      <form className="mb-3">
        <p className="mb-3 col-6">
          <label htmlFor="email" className="form-label">Email address</label>
          <input type="email" className="form-control" id="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
        </p>
        <DisableAfterClickButton text="Send Email" duration="500" handleClick={sendEmail} />
      </form>
    </main>
  );
}