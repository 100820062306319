import { useState } from "react";
import { trackAction } from "../../analyticsTracking";
import Actions from "../../Actions";

export default function AddLicenses({ addLicenses }) {

  const [count, setCount] = useState(0);

  const add = async (e) => {
    e.preventDefault();
    trackAction(Actions.clickedAddLicense);
    await addLicenses(count);
  };

  return (
    <form className="mb-4">
      <h4 className="mt-5 mb-4">Add Licenses</h4>
      <p>
        Licenses are added to your subscription using existing payment details (card number, expiration date, etc.).
      </p>
      <div className="row">
        <div className="col-md-3">
          <select className="form-select" style={{ width: "250px" }} onChange={(e) => setCount(parseInt(e.target.value))}>
            <option value="0">Number of Licenses</option>
            {[1, 2, 3, 4, 5].map((x) => <option key={x} value={x}>{x}</option>)}
          </select>
        </div>
        <div className="col-md-1">
          <button type="submit" className="btn btn-primary" onClick={add} disabled={count === 0}>Add</button>
        </div>
      </div>
    </form >
  );
}