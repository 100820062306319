import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";

export default function Pricing() {
  return (
    <main className="pricing">
      <Helmet>
        <title>{APPLICATION_NAME} | Pricing</title>
        <meta name="description" content="View our pricing options." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/pricing" />
      </Helmet>
      <header>
        <h1 className="page-title">Pricing</h1>
      </header>
      <section>
        <p>
          There are two versions available, the licensed version (<strong>$15/month</strong>) and the unlicensed version <strong>(Free)</strong>.
          The licensed version provides access to all of the tools.
          The unlicensed version allows access to the first 3 tools (#1 - #3) and has no expiration date.
          No account is needed to use the unlicensed version.
        </p>
        <p>
          To purchase a license, first create an account and log in, then select Licenses (under Account) from the top menu.
        </p>
      </section>
    </main >
  );
}