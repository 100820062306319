import { Helmet } from "react-helmet";
import ExternalLink from "../components/ExternalLink";
import { COMPANY_NAME, APPLICATION_NAME, APPLICATION_NAME_UPPER } from "../Constants";

export default function Terms() {

  return (
    <main className="terms legal">
      <Helmet>
        <title>{APPLICATION_NAME} | Terms</title>
        <meta name="description" content="View our terms and conditions." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/terms" />
      </Helmet>
      <section>
        <h1 className="page-title">Terms of Service</h1>
        <p>
          These terms of service ("TOS", "terms", "conditions", "disclaimers", "contract", or "agreement") are entered into
          between {COMPANY_NAME}, a California Limited Liability Company, doing business as {COMPANY_NAME},
          ("{COMPANY_NAME}", “we”, “us”, or “our”) and the entity purchasing or using services provided by or through the
          Platform (“Customer”, “you”, or “your”). If you are purchasing the Services for a third party, you represent that
          you have the legal authority to bind that party to these TOS and that you will be responsible for their use of
          the Services.
        </p>
        <p>
          By using the Platform and Services described in this document and marketed at {APPLICATION_NAME}, or subscribing to the
          services on {APPLICATION_NAME} website, you are attesting that you have read, understand, and agree to these
          Terms, Conditions and Disclaimers and that you agree that your relationship with us is goverened by the terms
          spelled out in the TOS. If you do not agree with these TOS, then you must immediately cease use
          of {APPLICATION_NAME} and any of {APPLICATION_NAME} services or Platforms.
        </p>
      </section>
      <section>
        <h5>Terms, Payments, and Refund Policy</h5>
        <p>
          {APPLICATION_NAME} Products are offered to you for a term of one month ("Monthly Subscription"). Your Monthly Subscription
          payment entitles you to one month of access to {APPLICATION_NAME} Products selected by you. Under the terms of the Monthly
          Subscription, your credit card is charged on the day you activate {APPLICATION_NAME} Products, and automatically charged
          every calendar month thereafter (at the monthly rate) until you cancel your service. For example, if you activate
          service on the 10th of a given month, your credit card will be charged on the 10th of all subsequent months. If you
          cancel service anytime during a Monthly Subscription billing period, service will terminate at the end of that billing
          period. Customers on the monthly plan may cancel service at any time and reinstate it at a later date without penalty.
        </p>
        <p>
          Multiple Devices or Computers: You may install {APPLICATION_NAME} Products to multiple devices as long as you are the license
          owner. Each license has a single owner. Licenses cannot be shared, sold, or transferred.
        </p>
        <p>
          Satisfaction Guarantee:  Cancel at any time within the first month and you'll receive a prompt, 100%, no questions asked refund
          for the pre-paid month. This offer is reserved for new users or accounts inactive for at least one year. Limit one per
          household per year.
        </p>
        <p>
          You are responsible for the payment of all fees and charges set out in your Order, including setup fees and recurring
          subscription fees (collectively, “Fees”). Fees are charged no more than three (3) days in advance of the date set out
          on your Order. All Fees must be made in US Dollars with a major credit card. All renewal charges will be automatically
          charged to such credit card on the due date of the payment.
        </p>
        <p>
          Fee Increases:  If necessary, {APPLICATION_NAME} may increase fees for {APPLICATION_NAME} Products at any time by giving thirty
          (30) days notice to Subscriber, sent to the email address provided during the payment process. Subscriber may
          terminate {APPLICATION_NAME} Products affected by the increase through {APPLICATION_NAME} Application. Fees for the
          {APPLICATION_NAME} Products specified may be increased by {APPLICATION_NAME} at any time during the term of this Agreement
          on electronic notice to Subscriber if {APPLICATION_NAME} becomes aware that any information provided by Subscriber
          is not accurate.
        </p>
      </section>
      <section>
        <h5>Termination</h5>
        <p>
          You may cancel your {APPLICATION_NAME} Products and terminate this User Agreement at any time and for any reason subject
          to the provisions of this User Agreement. {APPLICATION_NAME} reserves the right to terminate this User Agreement at any
          time and for any reason in its sole discretion.
        </p>
      </section>
      <section>
        <h5>No Advice</h5>
        <p>
          The analysis provided by {APPLICATION_NAME} is NOT INVESTMENT ADVICE and should not be construed as investment advice.
          The analysis provided by {APPLICATION_NAME} should not be the sole form of analysis you perform before making investment
          decisions. As indicated in this document, {APPLICATION_NAME} depends heavily upon third party data which may not be accurate
          and is not guaranteed to be accurate, thus, before making any investment decisions, please perform your own due
          diligence utilizing multiple sources of information as well as your own analysis of stock charts. For the avoidance
          of any doubt, and in the interest of clarity, {APPLICATION_NAME} DOES NOT PROVIDE INVESTMENT ADVICE, {APPLICATION_NAME}
          ANALYSIS IS PROVIDED ON AN AS-IS BASIS AND IS NOT GUARANTEED TO BE ACCURATE, AND YOU SHOULD NEVER MAKE INVESTMENT
          OR TRADING DECISIONS WITHOUT CONDUCTING YOUR OWN CAREFUL DUE DILIGENCE. For more information regarding online
          financial information, please visit <ExternalLink url="https://www.sec.gov/investor/pubs/cyberfraud.htm" />.
        </p>
        <p>
          {APPLICATION_NAME}'s backtesting feature attempts to calculate how a particular technical strategy might have performed
          in the past under perfect market conditions. Backtesting cannot predict the future and do not represent forecasts
          or predictions, implied or otherwise, of future performance of any strategy. Backtesting assumes a liquid market,
          perfect order execution, no slippage, does not account for brokerage commissions perfectly, and may be subject to
          errors in third party historical market data or {APPLICATION_NAME}'s computation. The backtesting feature is provided
          as-is and without warranty of any kind. BACKTESTING ANALYSIS IS PROVIDED ON AN AS-IS BASIS AND IS NOT GUARANTEED
          TO BE ACCURATE, AND YOU SHOULD NEVER MAKE INVESTMENT OR TRADING DECISIONS WITHOUT CONDUCTING YOUR OWN CAREFUL
          DUE DILIGENCE.
        </p>
        <p>
          {APPLICATION_NAME_UPPER} DOES NOT GIVE INVESTMENT ADVICE OR ADVOCATE THE PURCHASE, HOLDING OR SALE OF ANY SECURITY OR INVESTMENT
          BY ANY USER OF {APPLICATION_NAME_UPPER}. {APPLICATION_NAME_UPPER} DOES NOT PROVIDE ANY LEGAL, TAX, OR ACCOUNTING ADVICE OR ADVICE REGARDING
          THE SUITABILITY, PROFITABILITY, OR POTENTIAL VALUE OF ANY PARTICULAR INVESTMENT, SECURITY, OR INFORMATIONAL SOURCE.
          IF SUCH ADVICE IS SOUGHT, THE SERVICES OF A PROFESSIONAL ADVISOR SHOULD BE OBTAINED. PAST PERFORMANCE, WHETHER
          ACTUAL OR INDICATED BY TESTS OF STRATEGIES, IS NO GUARANTEE OF FUTURE SUCCESS.
        </p>
        <p>
          {APPLICATION_NAME}, and its directors, officers, agents and employees, may hold positions in securities mentioned on
          {APPLICATION_NAME} and may make purchases or sales from time to time in such securities.
        </p>
      </section>
      <section>
        <h5>Investment Decisions</h5>
        <p>
          You assume all risk associated with investment decisions that you decide to make based on information you find
          inside of the Platform or in {APPLICATION_NAME}’s analysis of a particular stock or security. You represent that you
          understand that {APPLICATION_NAME} depends on potentially inaccurate third party information and unproven formulas and
          mathematical sequences and thus cannot be depended on to provide accurate investment advice. Before executing any
          trade, you should consult with a registered and licensed professional and/or conduct your own extensive due
          diligence utilizing multiple sources of information. {APPLICATION_NAME} is NOT responsible if you do not heed this
          warning and make investment decisions based on information from {APPLICATION_NAME}.
        </p>
      </section>
      <section>
        <h5>User Conduct</h5>
        <p>
          {APPLICATION_NAME} reserves the right, in its sole discretion, to monitor and/or remove any information transmitted or received
          through {APPLICATION_NAME} products. {APPLICATION_NAME} also reserves the right, in its sole discretion, to modify and/or terminate your
          Username or any other identifying name used by you with respect to {APPLICATION_NAME} Products. You agree not to:
        </p>
        <ul>
          <li>
            Post material, non-public information about companies, without the specific written authorization to do so.
          </li>
          <li>
            Solicit, entice or otherwise seek to obtain any business, through advertisement, endorsement or any other means,
            from any user of {APPLICATION_NAME} Products.
          </li>
          <li>
            Post, transmit, copy, transfer, make available or otherwise distribute all, or any part or derivative of, the
            information provided by {APPLICATION_NAME} "Publishers" to {APPLICATION_NAME} users, even if such information is a fact, truth or
            general statement in which there are no proprietary intellectual property rights.
          </li>
          <li>
            Post, transmit, copy, license, sell, transfer, make available or otherwise distribute third party copyrighted
            information or in any way infringing on the intellectual property rights, contractual or fiduciary rights of others.
          </li>
          <li>
            Impersonate any person or entity, including, without limitation, any {APPLICATION_NAME} employee, any {APPLICATION_NAME}
            Publisher or other {APPLICATION_NAME} user.
          </li>
          <li>
            Post, transmit, copy, transfer, make available or otherwise distribute information or content that (i) may
            be considered bulk or junk e-mail (i.e. "spam") or (ii) contains any software virus or any other computer
            code, files or programs that are designed or intended to disrupt, damage or limit the functioning of any
            software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data
            or other information of any third party.
          </li>
          <li>
            Harvest or collect information about {APPLICATION_NAME} users or use any such information for the purpose of transmitting
            or facilitating transmission of unsolicited bulk e-mail or other unsolicited communication.
          </li>
        </ul>
        <p>
          Your use of {APPLICATION_NAME} Products is subject to all applicable local, state, national and international laws and
          regulations, including, without limitation, the rules of any national or other securities exchange, including,
          without limitation, the New York Stock Exchange, the American Stock Exchange or the NASDAQ. You agree not to
          transmit any material that could constitute or encourage conduct that could constitute a criminal offense, give
          rise to civil liability or otherwise violate any local, state, national or international law or regulation, or
          rule of a national or other securities exchange.
        </p>
        <p>
          You agree to not, under any circumstance, make modifications to {APPLICATION_NAME} source code or make use of it from
          applications other than those provided by {APPLICATION_NAME}. You may not not reverse engineer, decompile, disassemble,
          rent, lease, or lend {APPLICATION_NAME} software applications or libraries.
        </p>
        <p>
          You agree to provide {APPLICATION_NAME} with accurate information when setting up your account, during our relationship,
          and when corresponding with us. On occasion, we may need to communicate with you by e-mail or telephone about
          the Services. You agree to maintain a working e-mail address that is monitored daily and to promptly inform us
          of any changes to your e-mail address or phone number. We have no responsibility, or liability, for interruptions
          in the Services, or damages of any sort, based on communications that are misdirected because of your failure to
          provide us with updated contact information. You furthermore agree to complete any data entitlement surveys
          presented within the platform fully and truthfully in order to maintain compliance with exchange regulations.
        </p>
        <p>
          You agree that you are responsible for all statements made, and acts or omissions that occur, on your {APPLICATION_NAME}
          account while your password is being used. If you believe someone has used your password or account without your
          authorization, you must notify {APPLICATION_NAME} immediately. {APPLICATION_NAME} reserves the right to access and disclose
          any information, including, without limitation, user names of accounts and other information, to comply with
          applicable laws and lawful government requests.
        </p>
      </section>
      <section>
        <h5>Rights to Data and Content</h5>
        <p>
          The data and content available through {APPLICATION_NAME} is the property of {APPLICATION_NAME} or third parties and is
          protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws. Charts
          created through {APPLICATION_NAME} may be displayed, reformatted and printed for your personal as well as commercial
          use. You agree to comply with any copyright notices, information or restrictions contained
          in the data and content on or accessed through {APPLICATION_NAME}.
        </p>
      </section>
      <section>
        <h5>Disclaimer, Limitation of Liability and Waiver of Representations and Warranties</h5>
        <p>
          YOUR USE OF THE {APPLICATION_NAME_UPPER} APPLICATION, THE {APPLICATION_NAME_UPPER} WEBSITE, AND ANY OTHER {APPLICATION_NAME_UPPER} PRODUCTS AND SERVICES IS
          AT YOUR OWN SOLE RISK. {APPLICATION_NAME_UPPER} PRODUCTS AND SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" FOR YOUR PERSONAL USE
          ONLY. {APPLICATION_NAME_UPPER}, AND ITS DATA AND CONTENT PROVIDERS, MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS
          OR IMPLIED. {APPLICATION_NAME_UPPER} AND ITS DATA AND CONTENT PROVIDERS, EXPRESSLY DISCLAIM ANY IMPLIED WARRANTIES OF
          MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE. ANY QUOTATIONS PROVIDED AS PART OF {APPLICATION_NAME_UPPER} SERVICE
          MAY NOT REFLECT THE PRICES AT WHICH THE QUOTED SECURITIES MAY BE BOUGHT OR SOLD. YOU AGREE NOT TO HOLD {APPLICATION_NAME_UPPER},
          OR ANY OF ITS OFFICERS, DIRECTORS, OWNERS, AGENTS, EMPLOYEES, OR DATA AND CONTENT PROVIDERS, LIABLE FOR ANY DAMAGES
          ARISING FROM THE USE, DISCONTINUATION OR MODIFICATION OF ALL OR PART OF THE {APPLICATION_NAME_UPPER} SERVICE, OR ANY DATA OR
          CONTENT FOUND ON {APPLICATION_NAME_UPPER}, NOW OR IN THE FUTURE.
        </p>
        <p>
          YOU AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU WILL NOT, UNDER ANY CIRCUMSTANCES, INCLUDING,
          BUT NOT LIMITED TO, NEGLIGENCE, HOLD {APPLICATION_NAME_UPPER} OR ITS LICENSORS, AGENTS, EMPLOYEES, OFFICERS AND/OR THIRD PARTY
          VENDORS, LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES WHATSOEVER INCLUDING,
          BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, COST SAVINGS, REVENUE, BUSINESS, DATA OR USE, OR ANY OTHER
          PECUNIARY LOSS BY YOU, ANY END USERS OR ANY OTHER THIRD PARTY. YOU AGREE THAT THE FOREGOING LIMITATIONS APPLY
          WHETHER IN AN ACTION IN CONTRACT OR TORT OR ANY OTHER LEGAL THEORY AND APPLY EVEN IF {APPLICATION_NAME_UPPER} HAS BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR
          INCIDENTAL OR CONSEQUENTIAL DAMAGES; YOU AGREE THAT IN THOSE JURISDICTIONS {APPLICATION_NAME_UPPER}’S LIABILITY WILL BE LIMITED
          TO THE EXTENT PERMITTED BY LAW.
        </p>
        <p>
          IN PARTICULAR, YOU SHOULD BE AWARE THAT {APPLICATION_NAME_UPPER}, AND ITS DATA AND CONTENT PROVIDERS, DO NOT GUARANTEE OR
          INDEPENDENTLY VERIFY THE ACCURACY, COMPLETENESS, AVAILABILITY OR TIMELINESS OF ANY {APPLICATION_NAME_UPPER} PRODUCTS, OR ANY
          DATA OR CONTENT ASSOCIATED WITH {APPLICATION_NAME_UPPER} PRODUCTS. THE DATA AND CONTENT COULD INCLUDE TECHNICAL INACCURACIES
          OR TYPOGRAPHICAL ERRORS. {APPLICATION_NAME_UPPER}, AND ITS DATA AND CONTENT PROVIDERS, SHALL NOT BE RESPONSIBLE OR LIABLE,
          DIRECTLY OR INDIRECTLY, FOR ANY CLAIM, LOSS OR DAMAGE CAUSED BY YOUR USE OF OR RELIANCE ON OR INABILITY TO USE
          ANY {APPLICATION_NAME_UPPER} PRODUCTS, OR ANY DATA OR CONTENT ASSOCIATED WITH {APPLICATION_NAME_UPPER} PRODUCTS.
        </p>
        <p>
          IF YOU ARE DISSATISFIED WITH THE {APPLICATION_NAME_UPPER} PRODUCTS OR WITH THE TERMS AND CONDITIONS OF THIS USER AGREEMENT,
          YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP USING {APPLICATION_NAME_UPPER} PRODUCTS. NEITHER {APPLICATION_NAME_UPPER}, NOR ITS DATA OR
          CONTENT PROVIDERS, WILL PAY YOU ANY DAMAGES, INCLUDING BUT NOT LIMITED TO LOST PROFITS, INDIRECT, SPECIAL,
          EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, EVEN IF NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF
          SUCH DAMAGES ARE THE RESULT OF THE NEGLIGENCE OF {APPLICATION_NAME_UPPER} OR THE NEGLIGENCE OR WILLFUL MISCONDUCT OF ITS DATA
          OR CONTENT PROVIDERS.
        </p>
      </section>
      <section>
        <h5>Indemnification/Hold Harmless</h5>
        <p>
          You agree to indemnify and hold harmless {APPLICATION_NAME}, its officers, directors, owners, agents and employees,
          and any of its data or content providers, from any claim or demand, including reasonable attorneys fees, made
          by any third party due to or arising out of your use of {APPLICATION_NAME} Products, the violation of the Terms and
          Conditions of this User Agreement by you, or the infringement by you, or other user of {APPLICATION_NAME} Products
          using your {APPLICATION_NAME} account and password, of any intellectual property or other right of any person or
          entity. {APPLICATION_NAME} reserves the right to assume the exclusive defense and control of any matter otherwise
          subject to indemnification by you.
        </p>
      </section>
      <section>
        <h5>DMCA Designated Agent and Takedown Procedures</h5>
        <p>
          If you believe materials posted on this website infringe any copyright interest in a photographic, literary, visual
          or other artistic work you created or own, you must direct your concerns to our agent, designated under the Digital
          Millennium Copyright Act ("DMCA") to respond to such concerns at support@themarkettoolkit.com.
        </p>
        <p>
          The DMCA documents the Notice and Takedown Procedure steps to be followed when we receive a complaint from a copyright
          holder or agent of a copyright holder, hereafter referred to as the "Complainant". If you believe any user-generated
          content on this website infringes your copyright, you must submit by email to our designated DMCA Agent a DMCA Copyright
          Infringement Notice with the following:
        </p>
        <ol>
          <li>
            A physical or electronic signature of the Complainant.
          </li>
          <li>
            An identification of the infringed copyrighted work, or, if multiple copyrighted works are covered by a single
            notification, a list of all works alleged to have been infringed.
          </li>
          <li>
            An identification of the alleged infringing material and information reasonably sufficient to permit us to
            locate the material.
          </li>
          <li>
            Contact information of the Complainant, such as an address, telephone number, electronic mail address.
          </li>
          <li>
            A statement that the Complainant has a good faith belief that use of the material in the manner complained of
            is not authorized by the copyright owner, its agent, or the law.
          </li>
          <li>
            A statement that the information contained in the notification is accurate, and under penalty of perjury,
            that the Complainant is, or is authorized to act on behalf of, the copyright owner.
          </li>
        </ol>
        <p>
          If the notice is deemed valid, then we will proceed with the takedown procedure set forth in the DMCA.
        </p>
        <p>General</p>
        <p>
          The laws of the State of Californa, excluding its conflicts of law rules, govern this User Agreement and your
          use of {APPLICATION_NAME} Products. You also agree and expressly consent to the exercise of personal jurisdiction in
          the State of California. You agree to pay reasonable attorneys fees for {APPLICATION_NAME}’s enforcement of its rights
          under this User Agreement. No failure or delay in enforcing any right shall be a waiver of that or any other right.
          If any provision of this User Agreement is held to be invalid or unenforceable, such provision shall be struck and
          the remaining provisions shall be enforced. Headings are for reference purposes only and in no way define, limit,
          construe or describe the scope or extent of such section. This User Agreement sets forth the entire understanding
          and agreement between the parties with respect to the subject matter hereof.
        </p>
      </section>
      <section>
        <h5>Mandatory Binding Arbitration</h5>
        <p>
          All controversies or claims between or among the parties hereto, including, without limitation, those arising out
          of or relating to this Agreement, or the breach thereof, shall be settled by binding arbitration administered by
          the American Arbitration Association in accordance with its Consumer Arbitration Rules. Arbitration shall be
          held in the Fresno County, California. Judgment upon any arbitration award may be entered in any court having
          appropriate jurisdiction.
        </p>
      </section>
      <section>
        <h5>FRED API</h5>
        <p>
          This product uses the FRED® API but is not endorsed or certified by the Federal Reserve Bank of St. Louis.
          By using {APPLICATION_NAME} products, you are agreeing to be bound by the FRED® API Terms of Use, available here:&nbsp;
          <ExternalLink url="https://fred.stlouisfed.org/docs/api/terms_of_use.html" />
        </p>
      </section>
      <section>
        <h5>Privacy</h5>
        <p>
          These TOS incorporates our Privacy Policy by reference. To the extent that these agreements conflict, these TOS
          shall prevail, followed by the Privacy Policy. In certain cases, you may purchase services from us using another
          agreement which is also incorporated into these TOS. If that agreement conflicts with these TOS, the terms of that
          agreement will prevail over the TOS, but only to the extent that the agreements conflict. After that, the agreements
          will have the precedence set out above.
        </p>
      </section>
    </main >
  );
}