import { Helmet } from "react-helmet";
import ExternalLink from "../components/ExternalLink";
import { COMPANY_NAME, APPLICATION_NAME } from "../Constants";

export default function Privacy() {

  const COMPANY_NAME_UPPER = COMPANY_NAME.toUpperCase();

  return (
    <main className="privacy legal">
      <Helmet>
        <title>{APPLICATION_NAME} | Privacy</title>
        <meta name="description" content="View our privacy statement." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/privacy" />
      </Helmet>
      <header>
        <h1 className="page-title">Privacy Policy</h1>
        <p>
          This Privacy Policy describes how your personal information is collected, used, and shared when you visit
          or make a purchase from {COMPANY_NAME} (at www.themarkettoolkit.com) (the "Site"). This Privacy Policy is in
          addition to any user, or other, agreements you have entered in to with {COMPANY_NAME} and nothing in this
          Privacy Policy shall be construed as superseding or replacing any other such agreement.
        </p>
      </header>
      <section>
        <h5>Personal Information We Collect</h5>
        <p>
          When you visit the Site, we automatically collect certain information about your device, including information
          about your web browser, IP address, time zone, and some of the cookies that are installed on your device.
          Additionally, as you browse the Site, we collect information about the individual web pages or products that
          you view, what websites or search terms referred you to the Site, and information about how you interact
          with the Site. We refer to this automatically-collected information as "Device Information."
        </p>
        We collect Device Information using the following technologies:
        <ul>
          <li>
            "Cookies" are data files that are placed on your device or computer and often include an anonymous unique identifier.
            For more information about cookies, and how to disable cookies, visit <ExternalLink url="http://www.allaboutcookies.org" />.
          </li>
          <li>
            "Log files" track actions occurring on the Site, and collect data including your IP address, browser type, Internet
            service provider, referring/exit pages, and date/time stamps.
          </li>
          <li>
            "Web beacons," "tags," and "pixels" are electronic files used to record information about how you browse the Site.
          </li>
          <li>
            Additionally when you make a purchase or attempt to make a purchase through the downloaded application, we collect
            certain information from you, including your name, billing address, shipping address, payment information
            (including credit card numbers), email address, and phone number. We refer to this information as "Order Information."
          </li>
        </ul>
        <p>
          "Personal Information" in this Privacy Policy refers to Device Information and Order Information.
        </p>
      </section>
      <section>
        <h5>Use of Personal Information</h5>
        <p>
          We use the Order Information that we collect generally to fulfill any orders placed through the downloaded application
          (including processing your payment information and providing you with invoices and/or order confirmations).
          Also, we use this Order Information to communicate with you.
        </p>
        <p>
          We screen our orders for potential risk or fraud.We use the device information that we collect to help us screen for
          potential risk and fraud (in particular, your ip address), and more generally to improve and optimize our site
          (for example, by generating analytics about how our customers browse and interact with the site, and to assess the
          success of our marketing and advertising campaigns).
        </p>
        <p>
          We share your personal information with third parties to help us use your personal information, as described above.
          For example, we process payments through <ExternalLink url="https://stripe.com/" label="Stripe" />. We also use Google Analytics to help us understand how our customers
          use the site. You can read more about how google uses your personal information here: <ExternalLink url="https://www.Google.Com/intl/en/policies/privacy" />.
          You can also opt-out of google analytics here: <ExternalLink url="https://tools.Google.Com/dlpage/gaoptout" />.
        </p>
        <p>
          Also, we may also share your personal information to comply with applicable laws and regulations, to respond to a subpoena,
          search warrant or other lawful request for information we receive, or to otherwise protect our rights.
        </p>
        <p>
          We generally use personally identifiable data about you to make and process your orders, give you access to our services,
          provide you with customer assistance, prepare your account statements and maintain and develop software. We may use
          licensees and distributors from time to time to perform such services for us. We also may disclose information in
          response to requests from law enforcement agencies or government regulators. We refer to such uses as "Normal Uses."
        </p>
        <p>
          By purchasing our products, whether from us or through one of our licensees or distributors, you are consenting
          to our use and limited disclosure of personally identifiable data about you for Normal Uses. If you do not wish
          to consent to such use and disclosure of personally identifiable data about you, please do not purchase our products,
          whether directly or through a licensee or distributor.
        </p>
      </section>
      <section>
        <h5>Linked Websites</h5>
        <p>
          Our websites may contain links to third party websites, which may or may not be operated in conjunction with our
          websites. We do not monitor or control the information collected when you choose to "click through" links to
          these websites. The treatment of user data by the operators of third party websites may be different from ours.
          For details regarding their treatment of personally identifiable data about you, you will need to read their
          privacy policies or contact them.
        </p>
      </section>
      <section>
        <h5>Security</h5>
        <p>
          We take precautions to protect your information. When you submit sensitive information via the website, your information
          is protected both online and offline. Whenever we collect sensitive information (such as credit card data) that
          information is encrypted and transmitted to us in a secure way. You can verify this by looking for a closed lock
          icon at the bottom of your web browser or looking for "https" at the beginning of the address of the webpage.
          While we use encryption to protect sensitive information transmitted online, we also protect your information
          offline. Only employees who need the information to perform a specific job (for example, billing or customer service)
          are granted access to personally identifiable information. The computers/servers in which we store personally
          identifiable information are kept in a secure environment.
        </p>
      </section>
      <section>
        <h5>Unintentional Disclosure</h5>
        <p>
          While we have taken significant steps to prevent the inadvertent disclosure of your personally identifiable
          data to persons who, without authorization, break into and access our confidential electronic files in violation
          of existing law ("hackers"), we cannot guarantee that hackers will not penetrate the safeguards we have in place
          to protect your personally identifiable data. We will continue to evaluate and, where indicated, update our
          security precautions consistent with good business practices.
        </p>
      </section>
      <section>
        <h5>Do Not Track</h5>
        <p>
          Please note that we do not alter our site’s data collection and use practices when we see a Do Not Track signal from your browser.
        </p>
      </section>
      <section>
        <h5>Retention</h5>
        <p>
          When you place an order through the site, we will maintain your order information for our records unless and until you ask us
          to delete this information.
        </p>
      </section>
      <section>
        <h5>Age Restrictions</h5>
        <p>
          The site is not intended for individuals under the age of 18.
        </p>
      </section>
      <section>
        <h5>Rights to Information</h5>
        <p>
          You have the right to access personal information we hold about you and to ask that your personal information be
          corrected, updated, or deleted. If you would like to exercise this right, please contact us at support@themarkettoolkit.com.
        </p>
        <p>
          If you are a resident outside of the United States, note that we are processing your information in order to fulfill
          contracts we might have with you (for example if you make an order through the site), or otherwise to pursue our
          legitimate business interests listed above. Additionally, please note that your information will be transferred
          outside of your resident country, including to Canada and the United States.
        </p>
      </section>
      <section>
        <h5>Privacy Policy Updates</h5>
        <p>
          We may update this privacy policy from time to time in order to reflect, for example, changes to our practices
          or for other operational, legal or regulatory reasons.
        </p>
      </section>
      <section>
        <h5>Contact Us</h5>
        <p>
          For more information about our privacy practices, if you have questions, or if you would like to make a complaint,
          please contact us by e-mail at support@themarkettoolkit.com.
        </p>
      </section>
      <section>
        <h5>Disclaimer and Limitation of Liability</h5>
        <p>
          IF YOU ARE DISSATISFIED WITH THIS PRIVACY POLICY OR IF {COMPANY_NAME_UPPER} FAILS TO ABIDE BY THE TERMS OF THIS PRIVACY
          POLICY, YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP USING THE SERVICES PROVIDED BY {COMPANY_NAME_UPPER}. {COMPANY_NAME_UPPER}
          WILL NOT PAY YOU ANY DAMAGES, INCLUDING BUT NOT LIMITED TO LOST PROFITS, INDIRECT, SPECIAL,
          EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, EVEN IF NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN
          IF SUCH DAMAGES ARE THE RESULT OF THE NEGLIGENCE OF {COMPANY_NAME_UPPER}.
        </p>
      </section>
    </main >
  );
}