import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";

export default function NotFound() {
  return (
    <main className="not-found">
      <Helmet>
        <title>{APPLICATION_NAME} | Page Not Found (404)</title>
        <meta name="description" content="Page Not Found" />
      </Helmet>
      <header>
        <h3>Page Not Found</h3>
      </header>
      <p>
        The requested page does not exist.
      </p>
    </main>
  );
}