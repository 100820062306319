import FooterNavLink from "./FooterNavLink";
import { COMPANY_NAME } from "../Constants";

export default function Footer() {
  return (
    <footer className="footer fixed-bottom text-center">
      <nav>
        <FooterNavLink to="/faq">FAQ</FooterNavLink>
        <FooterNavLink to="/privacy">Privacy</FooterNavLink>
        <FooterNavLink to="/terms">Terms</FooterNavLink>
        <FooterNavLink to="/changelog">Changelog</FooterNavLink>
        <FooterNavLink to="/pricing">Pricing</FooterNavLink>
        <FooterNavLink to="/contact-us">Contact Us</FooterNavLink>
        <FooterNavLink to="/download">Download</FooterNavLink>
        <FooterNavLink to="/blog">Blog</FooterNavLink>
        <small className="footer-text">© 2024 {COMPANY_NAME}</small>
      </nav>
    </footer>
  );
}