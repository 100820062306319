import { Navigate } from 'react-router-dom'
import { useAuthValue } from '../AuthContext';

export default function AuthenticatedRoute({ children }) {
  const { currentUser } = useAuthValue()

  //executes three times - the first two times currentUser is always null
  if (currentUser && !currentUser?.emailVerified) {
    return <Navigate to='/sign-in' replace />
  }

  return children
}