import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";
import { apiGetToolsList } from "../apiService";
import ToolContent from "../components/ToolContent";
import Screenshot from "../components/Screenshot";
import LastUpdated from "../components/LastUpdated";
import { RUNNING_WEBSITE_URL } from "../Constants";

export default function ToolDetails() {

  const [tool, setTool] = useState(null);
  const { path } = useParams();

  useEffect(() => {
    const getData = async () => {
      const _tools = await apiGetToolsList();
      const _tool = _tools.find(x => x.path === path);
      setTool(_tool);
    }
    getData();
  }, [path])

  return (
    <main className="tool-details">
      <Helmet>
        <title>{APPLICATION_NAME} | Tool Details</title>
        <meta name="description" content="Learn how a specific tool can make you a better trader or investor." />
      </Helmet>
      <p>
        <Link rel="canonical" to={`${RUNNING_WEBSITE_URL}/tools/#${tool?.id}`}>Back to List</Link>
      </p>
      <h5>{tool?.title}</h5>
      <ToolContent tool={tool} full={true} />
      {
        tool?.screenshots.map((screenshot, i) =>
          <Screenshot key={i} subfolder="tool" name={screenshot} title={tool.title} />
        )
      }
      <LastUpdated updated={tool?.updated} />
    </main>
  );
}