import UserImportsLink from "./UserImportsLink";

export default function ToolContent({ tool, full }) {

  const hasUserImports = tool?.userImports.length > 0;

  const Wrapper = (props) => {
    return (
      <>
        <section className="text-muted">
          {props.children}
        </section>
        {full && hasUserImports ? <UserImportsLink /> : <></>}
      </>
    )
  };

  switch (tool?.id) {
    case "T1":
      return (
        <Wrapper>
          <p>
            View charts of selected U.S. Treasury rates and a custom yield curve over a custom date range.
          </p>
        </Wrapper>
      );
    case "T2":
      return (
        <Wrapper>
          <p>
            View charts of multiple employment reports, such as the Nonfarm Payrolls Report, the ADP Employment Report, and
            the JOLTS Report, over a custom date range.
          </p>
        </Wrapper>
      );
    case "T3":
      return (
        <Wrapper>
          <p>
            View charts of various inflation measurements, such as CPI, PCE and their core counterparts, as well as CPI
            components, over a custom date range.
          </p>
        </Wrapper>
      );
    case "T4":
      return (
        <Wrapper>
          <p>
            View charts of retail sales monthly and yearly changes, and the University of Michigan Consumer Sentiment Index,
            over a custom date range.
          </p>
        </Wrapper>
      );
    case "T5":
      return (
        <Wrapper>
          <p>
            View monthly and yearly changes to the M2 money supply over a custom date range.
          </p>
        </Wrapper>
      );
    case "T6":
      return (
        <Wrapper>
          <p>
            View charts of the nominal and real federal funds rates, as well as the Federal Reserve's
            balance sheet, over a custom date range.
          </p>
        </Wrapper>
      );
    case "T7":
      return (
        <Wrapper>
          <p>
            View charts of housing data, such as median home prices, the 30 year fixed mortgage rate, and
            the Case Schiller Price Index, over a custom date range.
          </p>
        </Wrapper>
      );
    case "T8":
      return (
        <Wrapper>
          <p>
            View a history of price targets for a company and a chart of those price targets along with
            the stock's prices the date the targets were published.
          </p>
        </Wrapper>
      );
    case "T9":
      return (
        <Wrapper>
          <p>
            View analyst upgrades and downgrade details, along with a summary table, for a company over a custom date range.
          </p>
        </Wrapper>
      );
    case "T10":
      return (
        <Wrapper>
          <p>
            View a symbol's price history in line or candlestick charts, one for each year.
            Add events, including custom events, to generated charts.
          </p>
        </Wrapper>
      );
    case "T11":
      return (
        <Wrapper>
          <p>
            View the earnings history for a company as well as upcoming earnings estimates.
            Year-over-year revenue and EPS changes are displayed as well.
          </p>
        </Wrapper>
      );
    case "T12":
      return (
        <Wrapper>
          <p>
            View charts of metrics, such as EBITDA and free cash flow, for income statements, cash flow statements,
            and balance sheets, over a selected date range.
            Year-over-year and quarter-over-quarter changes are displayed as well.
          </p>
        </Wrapper>
      );
    case "T13":
      return (
        <Wrapper>
          <p>
            View totals, such as the number of revenue and EPS beats and misses, for companies in a selected year and quarter.
            All individual companies are listed and can be optionally grouped by sector.
          </p>
        </Wrapper>
      );
    case "T14":
      return (
        <Wrapper>
          <p>
            View performance charts of entered symbols from or to a custom date range.
            If a benchmark symbol is entered, charted performance is relative to the benchmark.
          </p>
        </Wrapper>
      );
    case "T15":
      return (
        <Wrapper>
          <p>
            View rolling performance charts and tables for a symbol for a selected list of years.
            For example, view the three year performance of the S&P 500 from 2000 through 2024.
          </p>
        </Wrapper>
      );
    case "T16":
      return (
        <Wrapper>
          <p>
            View the annual returns, as well as largest drawdowns, for an entered list of symbols over a set of years.
            Counts included are the number of months and days up per year and the total number of up and down years.
          </p>
        </Wrapper>
      );
    case "T17":
      return (
        <Wrapper>
          <p>
            View monthly returns for an entered list of symbols.
            Specific years and months can be selected.
            Statistics are calculated for each month, such as the mean and median number of up months.
          </p>
        </Wrapper>
      );
    case "T18":
      return (
        <Wrapper>
          <p>
            View a symbol's performance by selected months, weeks, and days of the week.
            For example, MSFT's performance on Mondays and Fridays in the first and second
            weeks of the fourth quarter in years 2010-2020.
          </p>
        </Wrapper>
      );
    case "T19":
      return (
        <Wrapper>
          <p>
            View performance for one or more symbols by day of week over a custom date range.
          </p>
        </Wrapper>
      );
    case "T20":
      return (
        <Wrapper>
          <p>
            View a symbol's performance for a day range within a month.
            For example, April 5th through the 15th in years 2000-2015 or the first 10 trading days of the fourth quarter
            within the last 10 years.
          </p>
        </Wrapper>
      );
    case "T21":
      return (
        <Wrapper>
          <p>
            View performance summmaries of companies in the S&P 500 by year.
            There are additional filters available.
            For example, view the list of companies that were up at least 75% of years during the last 25 years.
          </p>
        </Wrapper>
      );
    case "T22":
      return (
        <Wrapper>
          <p>
            View a symbol's returns up to, the day of, and after rate changes.
            The number of days before and after the rate changes can be customized.
            Rate increases are calculated separately from rate decreases.
            Selecting a specific rate change displays a line or candlestick price chart centered around that change.
          </p>
        </Wrapper>
      );
    case "T23":
      return (
        <Wrapper>
          <p>
            View a symbol's returns up to, the day of, and after reporting earnings.
            The number of days before and after reporting earnings can be customized.
            Selecting a specific earnings row displays a line or candlestick price chart centered around that earning.
          </p>
        </Wrapper>
      );
    case "T24":
      return (
        <Wrapper>
          <p>
            View a symbol's returns up to, the day of, and after a selected holiday.
            The number of days before and after the holiday can be customized.
            Selecting a specific holiday row displays a line or candlestick price chart centered around that holiday.
          </p>
        </Wrapper>
      );
    case "T25":
      return (
        <Wrapper>
          <p>
            View a symbol's performance by U.S. President.
            Calculations can be narrowed by year range and political party.
          </p>
        </Wrapper>
      );
    case "T26":
      return (
        <Wrapper>
          <p>
            View a symbol's performance by U.S. President team year.
            Calculations can be narrowed by year range and political party.
          </p>
        </Wrapper>
      );
    case "T27":
      return (
        <Wrapper>
          <p>
            Compare earnings reactions between two companies over a selected year range.
            Related companies that have already reported can sometimes provide clues ahead of the later company's earnings report.
          </p>
        </Wrapper>
      );
    case "T28":
      return (
        <Wrapper>
          <p>
            Compare a ticker's performance across different time periods.
            A composite line series can be added as well.
            This is useful, for example, to see how a company typically performs during recessions.
          </p>
        </Wrapper>
      );
    case "T29":
      return (
        <Wrapper>
          <p>
            View a a symbol's drawdowns over a selected number of years.
            The drop threshold percentage can be customized as well as the strategy
            used to determine when a drawdown is over.
          </p>
        </Wrapper>
      );
    case "T30":
      return (
        <Wrapper>
          <p>
            View the Pearson correlation coefficient (r) between two symbols over a custom date range.
            The thresholds to determine highlighted positive vs. negative coefficients can be customized
            as well as the period length.
          </p>
        </Wrapper>
      );
    case "T31":
      return (
        <Wrapper>
          <p>
            View an ETF's performance, as well as the performance and weight of each of its components.
            Clicking each component row's view button will display a line or candlestick chart
            of that component's prices over the last 6 months.
          </p>
        </Wrapper>
      );
    case "T32":
      return (
        <Wrapper>
          <p>
            View a chart of the Chicago Fed National Conditions Index over a custom date range.
            Events, including custom events, can be added to the generated charts.
          </p>
        </Wrapper>
      );
    case "T33":
      return (
        <Wrapper>
          <p>
            View price and performance charts centered around major market events, such as COVID-19 and the Great Recession.
            The number of periods before and after selected events can be customized, as well as the chart type.
          </p>
        </Wrapper>
      );
    case "T34":
      return (
        <Wrapper>
          <p>
            View charts of crude oil and gas prices over a custom date range.
            Events, including custom events, can be added to charts.
          </p>
        </Wrapper>
      );
    case "T35":
      return (
        <Wrapper>
          <p>
            View monthly performance of companies in the S&P 500 over selected years.
            Statistics include the number of up and down months, mean and median performance,
            and minimum and maximum percent changes.
          </p>
        </Wrapper>
      );
    case "T36":
      return (
        <Wrapper>
          <p>
            View portfolio values, as well as values of individual positions.
            Tags can be used to filter positions, showing subtotals of positions sharing those tags.
            Positions are entered into .csv files.
            Combining multiple portfolios is supported.
            Cash totals are supported as well by using the symbol "$CASH".
          </p>
        </Wrapper>
      );
    case "T37":
      return (
        <Wrapper>
          <p>
            View real and nominal GDP (Gross Domestic Product) over a custom date range.
            Events, including custom events, can be added to charts.
          </p>
        </Wrapper>
      );
    case "T38":
      return (
        <Wrapper>
          <p>
            Compare the performance of ETFs and view sector weights of each.
            Shared holdings, along with their percentages in each ETF, can be listed as well.
          </p>
        </Wrapper>
      );
    case "T39":
      return (
        <Wrapper>
          <p>
            View an overview of a what a company does and metrics such as market cap and current price.
          </p>
        </Wrapper>
      );
    case "T40":
      return (
        <Wrapper>
          <p>
            Track dividends for a company over a selected year range.
            View dividends and divident yield per year.
            A line chart shows growth in annual dividends over time.
          </p>
        </Wrapper>
      );
    case "T41":
      return (
        <Wrapper>
          <p>
            View custom journal entries over a selected date range.
            Each entry can optionally be associated with a symbol.
            Clicking the View button next to each symbol displays a line or candlestick
            chart centered around that row's journal entry date.
            Events, including custom events, can be added to charts as well.
          </p>
        </Wrapper>
      );
    case "T42":
      return (
        <Wrapper>
          <p>
            View a table of upcoming earnings.
            Custom symbols can be entered and indexes can be added.
            Earnings dates and their proximity is displayed.
          </p>
        </Wrapper>
      );
    case "T43":
      return (
        <Wrapper>
          <p>
            Compare two symbols side-by-side over a custom date range.
            Cumulative performance, interval performance, and price history is displayed.
            Events, including custom events, can be added to charts.
          </p>
        </Wrapper>
      );
    case "T44":
      return (
        <Wrapper>
          <p>
            View the spread between different types of bonds, such as high-yield (junk) bonds and U.S. Treasuries, over a custom date range.
          </p>
        </Wrapper>
      );
    case "T45":
      return (
        <Wrapper>
          <p>
            Backtest yearly returns by applying different filters.
            Multiple filters can be applied with rules such as (filter A OR filter B) and filter C.
          </p>
          {full ?
            <p>
              Filters supported:
              <ul>
                <li>Consecutive Months Percent Change</li>
                <li>Date Range Percent Change</li>
                <li>Months Percent Change</li>
                <li>Presidential Party</li>
                <li>Presidential Term Year</li>
                <li>Year Inflation Minimum/Maximum</li>
                <li>Year in Years</li>
                <li>Year Percent Change</li>
                <li>Year Interest Rate Change</li>
              </ul>
            </p> : <></>
          }
        </Wrapper >
      );
    case "T46":
      return (
        <Wrapper>
          <p>
            View, sort, and filter S&P 500 companies categorized by sector and industry.
            Entering an optional benchmark will change all percentages from absolute to relative.
            Performance over multiple time periods is shown in tables for the companies in each industry.
          </p>
        </Wrapper>
      );
    default:
      return (
        <p>Tool description placeholder for Tool {tool?.id}...</p>
      );
  }

}