import { useState } from "react";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import DisableAfterClickButton from "../DisableAfterClickButton";

export default function CardForm({ buttonText, onClick }) {

  const [name, setName] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return '';
  }

  const handleClick = async (e) => {
    e.preventDefault();
    const elem = elements.getElement(CardElement);
    await onClick(elem, name);
  }

  const cardOptions = {
    hidePostalCode: true
  }

  return (
    <form className="mb-5">
      <p className="mb-3 col-6">
        <label htmlFor="name" className="form-label">Name</label>
        <input type="text" className="form-control" id="name"
          value={name} required onChange={(e) => setName(e.target.value)} />
      </p>
      <p className="mb-1 col-6">
        <label className="form-label">Credit Card</label>
      </p>
      <div className="mb-3 col-6 form-control card-container">
        <CardElement options={cardOptions} />
      </div>
      <div className="mt-3 mb-5 col-6">
        <DisableAfterClickButton text={buttonText} duration="500" disabled={!name.length} handleClick={handleClick} />
      </div>
    </form>
  );
}