import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";
import DownloadAppLinks from "../components/DownloadAppLinks";
import Screenshot from "../components/Screenshot";

export default function Quickstart() {

  return (
    <main className="quickstart">
      <Helmet>
        <title>{APPLICATION_NAME} | Quickstart</title>
        <meta name="description" content={`Learn how to get up and running with the ${APPLICATION_NAME} desktop application.`} />
        <link rel="canonical" href="https://www.themarkettoolkit.com/quickstart" />
      </Helmet>

      <header>
        <h1 className="page-title">Quickstart</h1>
        <p>
          The Quickstart is designed to walk through the steps of downloading and executing your first tool successfully.
          We will download and install the application, download a tool, and then execute it.
        </p>
      </header>

      <section>
        <h5>Step 1 - Download and install the {APPLICATION_NAME} application</h5>
        <p>
          Click one of the Download buttons below and install the {APPLICATION_NAME} application onto your computer.
        </p>
        <p>
          <DownloadAppLinks />
        </p>
      </section>

      <section>
        <h5>Step 2 - Launch the {APPLICATION_NAME} Application</h5>
        <p>
          Launch the application.
          Launching the application will take care of setup tasks, such as creating folders and files that it needs to run.
          The application will detect updates and update itself and its tools automatically if needed every time it is launched.
          All price data, except for S&P 500 returns by month and year, is retrieved on-demand and will always be current.
          Economic data, which is updated infrequently, typically monthly, is cached by the application to make it more responsive.
          To clear the cache while the application is running, select View, Reload from the top menu.
        </p>
        <Screenshot subfolder="quickstart" name="start.png" title="Application after launch" size="80%" />
      </section>

      <section>
        <h5>Step 3 - Download a Tool</h5>
        <p>
          Select Tools, Download from the top menu. This launches a new modal. Select "#1 Economy - US Treasury Rates" from the
          dropdown list, then click Download. This downloads the tool file to your computer.
          Alternatively, you can filter tools by typing into the text box. For example, typing "#1".
          Click Close to close the open modal.
          A tool is technically a JavaScript file containing code for retrieving data, processing it, and generating tables and charts.
        </p>
        <Screenshot subfolder="quickstart" name="downloaded.png" title="Download Tools Modal" size="80%" />
      </section>

      <section>
        <h5>Step 4 - Execute the Downloaded Tool</h5>
        <p>
          Select "#1 Economy - US Treasury Rates" in the sidebar (located on the left side of the application) and click the green Execute button
          located near the bottom of the sidebar.
          The tool will be executed and charts will be displayed in the application's main content window.
          Make sure that at least one checkbox is selected in the sidebar so that there are results to display.
        </p>
        Other features:
        <ul>
          <li>The sidebar can be opened and closed by selecting View, Toggle Sidebar from the top menu or using the CTRL+S (in Windows)
            or CMD+S (in macOS) shortcut.</li>
          <li>CTRL+1 and CTRL+2 (in Windows) or CMD+1 and CMD+2 (in macOS) are shortcuts for zooming in and out.</li>
          <li>F11 (or View, Toggle Full Screen ) will toggle full screen mode.</li>
          <li>There are Symbol Reference and Symbol Search modals under The Market Toolkit, then Symbol, top-level menu.</li>
        </ul>
        <Screenshot subfolder="quickstart" name="executed.png" title="Tool Executed" size="80%" />
      </section>

      <section>
        <h5>Step 5 - Add License Credentials</h5>
        <p>
          If you have purchased a license, select The Market Toolkit, License from the top menu. Enter the username and password that
          you registered with on this website as well as the purchased license key.
          If the sign-in was successful, click the Close button to close the modal.
        </p>
        <Screenshot subfolder="quickstart" name="licensed.png" title="License Modal" size="80%" />
        <h5>Download Tools - Licensed</h5>
        <p>
          After signing in successfully, note that the text in the titlebar located at the top of the application changes from
          "Unlicensed" to "Licensed". The Tools modal (opened by selecting Tools, Download) will now show all
          tools available for download.
        </p>
        <Screenshot subfolder="quickstart" name="all_tools_available.png" title="Download Tools Modal - Licensed" size="80%" />
      </section>

      <section>
        <h5>User Imports Folder</h5>
        <p>
          Some of the tools work with CSV files that are downloaded into the User Imports folder.
          CSV files can be opened with editors such as Microsoft Excel or text editors like Windows Notepad.
          To open the User Imports folder, select The Market Toolkit, then Open Folder, then User Imports Folder from the top menu.
        </p>
      </section>

      <section>
        <h5>Presets</h5>
        <p>
          Presets save the sidebar settings to a file so they can be easily restored later.
          To add a preset, click the Add Preset button in the sidebar, enter a preset name and click the Save button.
        </p>
        <Screenshot subfolder="quickstart" name="preset_added.png" title="Creating a new preset" size="80%" />
        <p>
          After the preset has been added, selecting it from the Presets drop down, located
          in the sidebar under the selected tool drop down, will set the sidebar interface selections to those that existed
          when the preset was added.
        </p>
        <Screenshot subfolder="quickstart" name="preset_selected.png" title="The new preset selected" size="80%" />
        <p>
          To manage the list of existing presets, open the Presets modal by selecting The Market Toolkit,
          Presets from the top menu. The modal allows presets to be renamed and deleted.
        </p>
        <Screenshot subfolder="quickstart" name="presets_manage.png" title="The new preset selected" size="80%" />
      </section>

    </main>
  );
}