import { useState } from "react";

export default function DisableAfterClickButton({ text, duration, handleClick, ...props }) {

  const [disabled, setDisabled] = useState(false);

  const _handleClick = (e) => {

    e.preventDefault();

    if (disabled) {
      return;
    }
    else {
      handleClick(e);
      setDisabled(true);
      if (duration > 0) { //pass 0 or -1 to disable indefinitely
        setTimeout(() => {
          setDisabled(false);
        }, duration);
      }
    }

  }

  return <button type="submit" className="btn btn-primary" disabled={disabled} onClick={_handleClick} {...props}>{text}</button>
}