const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiUpdateEmail = async (uid, oldEmail, newEmail) => {
  const url = `${API_BASE_URL}/.netlify/functions/update-email`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      uid: uid,
      oldEmail: oldEmail,
      newEmail: newEmail
    })
  }
  const response = await fetch(url, options);
  responseCheck(response);
}

const apiSetupCheckout = async (uid, email, count) => {
  const url = `${API_BASE_URL}/.netlify/functions/setup-checkout`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      uid: uid,
      email: email,
      count: count
    })
  }
  const response = await fetch(url, options);
  responseCheck(response);
  const data = await response.json();
  return data;
}

const apiCompleteCheckout = async (uid, pmid) => {
  const url = `${API_BASE_URL}/.netlify/functions/complete-checkout?uid=${uid}&pmid=${pmid}`;
  const response = await fetch(url);
  responseCheck(response);
  const data = await response.json();
  return data;
}

const apiGetLicenses = async (uid) => {
  const url = `${API_BASE_URL}/.netlify/functions/get-licenses?uid=${uid}`;
  const response = await fetch(url);
  responseCheck(response);
  const data = await response.json();
  return data;
}

const apiAddLicenses = async (customerId, subscriptionId, count) => {
  const url = `${API_BASE_URL}/.netlify/functions/add-licenses`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      customerId: customerId,
      subscriptionId: subscriptionId,
      count: count
    })
  }
  const response = await fetch(url, options);
  responseCheck(response);
}

const apiRemoveLicenses = async (customerId, subscriptionId, licenseKeys) => {
  const url = `${API_BASE_URL}/.netlify/functions/remove-licenses`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      customerId: customerId,
      subscriptionId: subscriptionId,
      licenseKeys: licenseKeys
    })
  }
  const response = await fetch(url, options);
  responseCheck(response);
}

const apiGetSubscriptions = async (uid) => {
  const url = `${API_BASE_URL}/.netlify/functions/get-subscriptions?uid=${uid}`;
  const response = await fetch(url);
  responseCheck(response);
  const data = await response.json();
  return data;
}

const apiUpdateCard = async (subscriptionId, paymentMethodId) => {
  const url = `${API_BASE_URL}/.netlify/functions/update-card`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      subscriptionId: subscriptionId,
      paymentMethodId: paymentMethodId
    })
  }
  const response = await fetch(url, options);
  responseCheck(response);
}

const apiGetToolsList = async () => {
  const url = '/tools/tools.json'
  const response = await fetch(url);
  responseCheck(response);
  const data = await response.json();
  return data.filter(x => !x.tags?.includes("hidden"));
}

const apiGetToolCode = async (tool) => {
  const url = `/tools/${tool.fileName}`;
  const response = await fetch(url);
  responseCheck(response);
  let code = await response.text();
  code = code.replace('../../tmt-lib.js', 'tmt-lib');
  return code;
}

const apiDeleteCustomer = async (uid) => {
  const url = `${API_BASE_URL}/.netlify/functions/delete-customer`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      uid: uid
    })
  }
  const response = await fetch(url, options);
  responseCheck(response);
  const data = await response.json();
  return data;
}

const apiGetBlogArticlesList = async () => {
  const url = '/blog_articles.json'
  const response = await fetch(url);
  responseCheck(response);
  const data = await response.json();
  return data;
}

const responseCheck = async (response) => {
  if (!response.ok) {
    const message = await response.json();
    throw message;
  }
}

export {
  apiUpdateEmail,
  apiSetupCheckout,
  apiCompleteCheckout,
  apiGetLicenses,
  apiAddLicenses,
  apiRemoveLicenses,
  apiGetSubscriptions,
  apiUpdateCard,
  apiGetToolsList,
  apiGetToolCode,
  apiDeleteCustomer,
  apiGetBlogArticlesList
}