import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DisableAfterClickButton from "../DisableAfterClickButton";

export default function BuyLicenses({ setupCheckout }) {

  const navigate = useNavigate()

  const [count, setCount] = useState(0);

  const buy = async (e) => {
    e.preventDefault();
    const result = await setupCheckout(count);
    navigate({
      pathname: '/checkout',
      search: `?sec=${result.sec}&count=${count}`
    });
  };

  return (
    <form className="mb-4">
      <h4 className="mt-5 mb-4">Buy Licenses</h4>
      <div className="row">
        <div className="col-md-3">
          <select className="form-select" onChange={(e) => setCount(parseInt(e.target.value))}>
            <option value="0">Number of Licenses</option>
            {[1, 2, 3, 4, 5].map((x) => <option key={x} value={x}>{x}</option>)}
          </select>
        </div>
        <div className="col-md-1">
          <DisableAfterClickButton text="Checkout" disabled={count === 0} duration="200" handleClick={buy} />
        </div>
      </div>
    </form>
  );
}