import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
  signOut,
  AuthErrorCodes,
  EmailAuthProvider,
  deleteUser
} from "firebase/auth";
import { auth } from "../../firebase-config";
import { useAuthValue } from "../../AuthContext";
import { apiUpdateEmail, apiDeleteCustomer } from "../../apiService";
import { APPLICATION_NAME } from "../../Constants";
import DisableAfterClickButton from "../../components/DisableAfterClickButton";

export default function Profile() {

  const { currentUser } = useAuthValue();
  const navigate = useNavigate()

  const [currentPassword1, setCurrentPassword1] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [updateEmailMessage, setUpdateEmailMessage] = useState(null);

  const [currentPassword2, setCurrentPassword2] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [updatePasswordMessage, setUpdatePasswordMessage] = useState(null);

  const [currentPassword3, setCurrentPassword3] = useState('');
  const [deleteText, setDeleteText] = useState('');
  const [deleteAccountMessage, setDeleteAccountMessage] = useState(null);

  const handleUpdateEmail = async (e) => {

    try {
      e.preventDefault();
      var credential = EmailAuthProvider.credential(currentUser.email, currentPassword1);
      await reauthenticateWithCredential(currentUser, credential);
      await updateEmail(currentUser, newEmail);
      await apiUpdateEmail(currentUser.uid, null, newEmail);
      await signOut(auth);
      navigate("/sign-in");
    }
    catch (error) {
      switch (error.code) {
        case AuthErrorCodes.EMAIL_EXISTS:
          setUpdateEmailMessage("New email is already taken.");
          break;
        case AuthErrorCodes.INVALID_PASSWORD:
          setUpdateEmailMessage("Incorrect current password.");
          break;
        default:
          setUpdateEmailMessage(error.message);
      }
    };
  }

  const handleUpdatePassword = async (e) => {

    try {
      e.preventDefault();
      var credential = EmailAuthProvider.credential(currentUser.email, currentPassword2);
      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, newPassword);
      await signOut(auth);
      navigate("/sign-in");
    }
    catch (error) {
      switch (error.code) {
        case AuthErrorCodes.WEAK_PASSWORD:
          setUpdatePasswordMessage("New password must be at least 6 characters.");
          break;
        case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
          setUpdatePasswordMessage("Incorrect current password.");
          break;
        case AuthErrorCodes.INVALID_PASSWORD:
          setUpdatePasswordMessage("Incorrect current password.");
          break;
        default:
          setUpdatePasswordMessage(error.message);
      }
    };
  }

  const handleDeleteAccount = async (e) => {
    try {
      e.preventDefault();
      var credential = EmailAuthProvider.credential(currentUser.email, currentPassword3);
      await reauthenticateWithCredential(currentUser, credential);
      await apiDeleteCustomer(currentUser.uid); //stripe
      await deleteUser(currentUser);  //firebase
      await signOut(auth);
      navigate({
        pathname: '/',
        search: '?action=accountDeleted'
      });
    }
    catch (error) {
      switch (error.code) {
        case AuthErrorCodes.INVALID_PASSWORD:
          setDeleteAccountMessage("Incorrect current password.");
          break;
        default:
          setDeleteAccountMessage(error.message);
      }
    }
  }

  return (
    <main className="profile">
      <Helmet>
        <title>{APPLICATION_NAME} | Profile</title>
        <meta name="description" content="Your account profile. You can update your email address, update your password, or delete your account." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/profile" />
      </Helmet>
      <h1 className="page-title mb-4">Profile</h1>
      <section className="profile-section">
        <h5 className="mb-3">Update Email Address</h5>
        <p className="text-danger">{updateEmailMessage ?? ""}</p>
        <form className="mb-5">
          <p>
            Current email address: <strong>{currentUser?.email}</strong>
          </p>
          <p>
            After your email has been updated, you will be signed out and redirected back to the sign-in page.
          </p>
          <p className="mb-3 col-6">
            <label htmlFor="current_password_1" className="form-label">Current Password</label>
            <input type="password" className="form-control" id="current_password_1"
              value={currentPassword1} required onChange={(e) => setCurrentPassword1(e.target.value)} />
          </p>
          <p className="mb-3 col-6">
            <label htmlFor="new_email" className="form-label">New Email</label>
            <input type="email" className="form-control" id="new_email"
              value={newEmail} required onChange={(e) => setNewEmail(e.target.value)} />
          </p>
          <DisableAfterClickButton text="Submit" duration="500" handleClick={handleUpdateEmail} />
        </form>
      </section>

      <section className="profile-section">
        <h5 className="mb-3">Update Password</h5>
        <p className="text-danger">{updatePasswordMessage ?? ""}</p>
        <form className="mb-5">
          <p>
            After your password has been updated, you will be signed out and redirected back to the sign-in page.
          </p>
          <p className="mb-3 col-6">
            <label htmlFor="current_password_2" className="form-label">Current Password</label>
            <input type="password" className="form-control" id="current_password_2"
              value={currentPassword2} required onChange={(e) => setCurrentPassword2(e.target.value)} />
          </p>
          <p className="mb-3 col-6">
            <label htmlFor="new_password" className="form-label">New Password</label>
            <input type="password" className="form-control" id="new_password"
              value={newPassword} required onChange={(e) => setNewPassword(e.target.value)} />
            <span className="form-text">Password must be at least 6 characters.</span>
          </p>
          <DisableAfterClickButton text="Submit" duration="500" handleClick={handleUpdatePassword} />
        </form>
      </section>

      <section className="profile-section">
        <h5 className="mb-3">Delete Account</h5>
        <p className="text-danger">{deleteAccountMessage ?? ""}</p>
        <form className="mb-5">
          <p>
            Accounts and any existing license keys are deleted permanently. Are you sure?
          </p>
          <p className="mb-3 col-6">
            <label htmlFor="current_password_3" className="form-label">Current Password</label>
            <input type="password" className="form-control" id="current_password_3"
              value={currentPassword3} required onChange={(e) => setCurrentPassword3(e.target.value)} />
          </p>
          <p className="mb-3 col-6">
            <label htmlFor="delete_text" className="form-label">Type <strong>DELETE</strong> in the text field below to confirm</label>
            <input type="text" className="form-control" id="delete_text"
              value={deleteText} onChange={(e) => setDeleteText(e.target.value)} />
          </p>
          <button type="submit" className="btn btn-danger" onClick={handleDeleteAccount}
            disabled={deleteText !== "DELETE"}>Delete Account</button>
        </form>
      </section>

    </main >
  );
}