import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";
import dayjs from 'dayjs';

export default function Changelog() {

  const changes = [];
  const add = (date, details) => changes.push({ date, details });

  add("2023-04-08", ["Website launch", "Added image compression for screenshots"]);
  add("2023-04-13", ["Added prices table to S8 Price History Tool"]);
  add("2023-04-15", ["Added presets feature to desktop application"]);
  add("2023-04-21", ["Added filter boxes to tables"]);
  add("2023-04-22", ["Added S37 Changes Above and Below Tool"]);
  add("2023-04-24", ["Added Proximity Column to S36 Upcoming Earnings Tool"]);
  add("2023-04-25", ["Added Days up and Days Down S15 Performance By Year Tool"]);
  add("2023-04-28", ["Added Proximity to S36 Upcoming Earnings Tool", "Added performance chart to S37 Changes Above and Below"]);
  add("2023-04-29", ["Added summary table to S20 Symbol Earnings Tool"]);
  add("2023-04-30", ["Added S28 Earnings By Quarter Tool"]);
  add("2023-05-01", ["Added debounce to table filters"]);
  add("2023-05-02", ["Added S39 Price History - Multiple Tool"]);
  add("2023-05-05", ["Added composition to S9 Portfolio Overview Tool"]);
  add("2023-05-07", ["Added charting to S39 Price History - Multiple Tool"]);
  add("2023-05-08", ["Added S40 ETF Overview Tool"]);
  add("2023-05-15", ["Switched to friendlier tool URLs on website"]);
  add("2023-05-16", ["Improved validations in multiple tools"]);
  add("2023-05-19", ["Added chart type dropdowns to S8, S17, and S39"]);
  add("2023-05-20", ["Added 52 Week Bar Chart to S5 Performance Tool"]);
  add("2023-05-22", ["Updated tool names to use categorical - name format"]);
  add("2023-05-28", ["Added Market Cap to S6 and S9", "Added checkbox options to S31", "Added composite line option to S33"]);
  add("2023-05-30", ["Added Composite option to S15"]);
  add("2023-06-01", ["Added S41 Price Targets Tool", "Addes S42 Upgrades and Downgrades Tool"]);
  add("2023-06-02", ["Updated all screenshots"]);
  add("2023-06-07", ["Added Open button to export and open table data"]);
  add("2023-06-09", ["Added Benchmark input to S6"]);
  add("2023-06-11", ["Added Benchmark input to S5"]);
  add("2023-06-13", ["Added S43 S&P 500 Performance By Year"]);
  add("2023-06-15", ["Updated tool table and chart titles"]);
  add("2023-06-20", ["Added S44 Institutional Holders Tool"]);
  add("2023-06-24", ["Added enabling/disabling capability to user interface controls"]);
  add("2023-06-28", ["Re-render tables on filtering and sorting"]);
  add("2023-07-11", ["Updated chart and table titles"]);
  add("2023-07-12", ["Added Open button to charts"]);
  add("2023-07-20", ["Added tags support to S9", "Added imports folder for system imports"]);
  add("2023-07-22", ["Added benchmark input to S9"]);
  add("2023-07-25", ["Added S45 Symbols Statistics"]);
  add("2023-08-04", ["Reorganized S38 table columns"]);
  add("2023-08-07", ["Added event support to multiple tools"]);
  add("2023-08-09", ["Added returns by year and returns by month bar charts to S30", "Added statistics to S45"]);
  add("2023-08-11", ["Updated fuzzy date matching in S39"]);
  add("2023-08-18", ["Added S46 Trades Overview Tool"]);
  add("2023-08-21", ["Added summary table to S46"]);
  add("2023-08-23", ["Deleted S34 and renamed S46 to S34"]);
  add("2023-08-28", ["Added support for multiple events CSV files", "Added start and end dates to S41 and S42"]);
  add("2023-08-29", ["Added Select button to S6 and S40", "Fixed table cell vertical alignment", "Fixed chart tooltip y-value formatting"]);
  add("2023-08-31", ["Added drawdowns to S15"]);
  add("2023-10-27", ["Added tool notifications to sidebar and Download Tools modal"]);
  add("2023-10-28", ["Add sorting to dividends"]);
  add("2023-11-03", ["Add up-down colors to bar charts"]);
  add("2023-11-22", ["Add dynamic minimum sizing to charts"]);
  add("2023-12-16", ["Add years checkboxes component", "Add selected years to accordion help text"]);
  add("2023-12-17", ["Add Toggle button to expand and collapse tables and charts"]);
  add("2023-12-18", ["Add EPS and revenue changes to S11"]);
  add("2023-12-21", ["Add summary table functionality to economic tools"]);
  add("2023-12-26", ["Add show/hide chart title text on expand and collapse"]);
  add("2023-12-28", ["Change title bar colors and minor UI renamings"]);
  add("2023-12-31", ["Add keyboard shortcuts and shortcuts modal"]);
  add("2024-01-05", ["Add S39 Company Overview"]);
  add("2024-01-06", ["Improved code for expanding/collapsing outputs"]);
  add("2024-01-07", ["Add S40 Dividend Tracker"]);
  add("2024-01-18", ["Many small code improvements and bug fixes"]);
  add("2024-01-20", ["Replace website icons"]);
  add("2024-01-22", ["Add T41 Market Journal"]);
  add("2024-01-23", ["Add text to loading spinners"]);
  add("2024-01-27", ["Fix loading spinner alignment", "Add customized export file names to tables and charts"]);
  add("2024-01-28", ["Update T14, T31, and T41"]);
  add("2024-01-29", ["Added box containers to categorize tool inputs into sections."]);
  add("2024-01-30", ["Added support for mutiple date inputs."]);
  add("2024-01-31", ["Split years checkboxes into three columns."]);
  add("2024-02-05", ["Added special cash symbol into T36."]);
  add("2024-02-06", ["Added T43 Symbol Comparison tool."]);
  add("2024-02-13", ["Support multiple portfolios in T36."]);
  add("2024-02-14", ["Updated screenshots."]);
  add("2024-02-22", ["Add script versioning."]);
  add("2024-02-26", ["Add year-over-year and quarter-over-quarter changes to T12."]);
  add("2024-03-29", ["Add T45 Backtest By Year tool."]);
  add("2024-04-02", ["Add 'OR' table filtering using pipes."]);
  add("2024-04-05", ["Add T46 Market - S&P 500 Browser."]);
  add("2024-04-08", ["Add Dynamic Filters to T46 Market - S&P 500 Browser."]);
  add("2024-04-09", ["Add Highlighting to T10."]);
  add("2024-04-27", ["Add Next Earning Dates to T46."]);
  add("2024-04-28", ["Add Earnings option to markers box select input."]);
  add("2024-05-30", ["Add Up and Down percent options to markets box select input."]);

  return (
    <main className="changelog">
      <Helmet>
        <title>{APPLICATION_NAME} | Changelog</title>
        <meta name="description" content={`View a history of improvements to the ${APPLICATION_NAME} website and desktop application.`} />
        <link rel="canonical" href="https://www.themarkettoolkit.com/changelog" />
      </Helmet>
      <header>
        <h1 className="page-title">Changelog</h1>
      </header>
      {
        changes.map((change, i) => {
          return <section key={i}>
            <time dateTime={change.date}>{dayjs(change.date).format('M/D/YYYY')}</time>
            <ul>
              {
                change.details.map((detail, j) => {
                  return <li key={j}>{detail}</li>
                })
              }
            </ul>
          </section>
        })
      }
    </main>
  );
}