import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useAuthValue } from "../../AuthContext";
import { apiCompleteCheckout } from "../../apiService";
import { APPLICATION_NAME } from "../../Constants";
import CardForm from "../../components/licensing/CardForm";

export default function Checkout() {

  const PRICE = 15;

  const { currentUser } = useAuthValue();

  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  const count = searchParams.has('count') ? parseInt(searchParams.get('count')) : null;

  if (!stripe || !elements) {
    return '';
  }

  const handleClick = async (cardElement, name) => {

    const sec = searchParams.get('sec');

    let { error, paymentIntent } = await stripe.confirmCardPayment(sec, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        }
      }
    });

    if (error) {
      setMessage(error.message);
      return;
    }

    switch (paymentIntent.status) {
      case 'succeeded':
        await apiCompleteCheckout(currentUser.uid, paymentIntent.payment_method);
        navigate('/Licenses');
        break;
      case 'requires_payment_method':
        setMessage('Payment failed. Please try another payment method.');
        break;
      default:
        setMessage("Something went wrong. Please email support@themarkettoolkit.com.")
    }

  }

  return (
    <main className="check-out">
      <Helmet>
        <title>{APPLICATION_NAME} | Check Out</title>
        <meta name="description" content={`Complete your purchase of ${APPLICATION_NAME} licenses.`} />
        <link rel="canonical" href="https://www.themarkettoolkit.com/checkout" />
      </Helmet>
      <h3 className="mb-3">Checkout</h3>
      {
        count ?
          <h5 className="mb-3">
            {count === 1 ? "1 License" : `${count} Licenses`} X ${PRICE}/month = ${count * PRICE}/month
          </h5>
          : <></>
      }
      <p className="text-danger">{message ?? ""}</p>
      <CardForm buttonText="Place Order" onClick={handleClick} />
    </main>
  );
}