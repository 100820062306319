import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";
import DownloadAppLinks from "../components/DownloadAppLinks";

export default function Download() {
  return (
    <main className="download">
      <Helmet>
        <title>{APPLICATION_NAME} | Download</title>
        <meta name="description" content={`Download ${APPLICATION_NAME} cross-platform desktop application.`} />
        <link rel="canonical" href="https://www.themarkettoolkit.com/download" />
      </Helmet>
      <header>
        <h1 className="page-title">Download {APPLICATION_NAME} Desktop Application</h1>
      </header>
      <section>
        <p>
          Click one of the Download buttons below to download {APPLICATION_NAME} application installation file.
        </p>
        <p>
          <DownloadAppLinks />
        </p>
      </section>
    </main>
  );
}