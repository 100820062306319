import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";

export default function FAQ() {
  return (
    <main className="faq">
      <Helmet>
        <title>{APPLICATION_NAME} | Frequently-Asked Questions</title>
        <meta name="description" content="View the list of Frequently-Asked Questions (FAQ)." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/faq" />
      </Helmet>
      <header>
        <h1 className="page-title">FAQ (Frequently-Asked Questions)</h1>
      </header>
      <section>
        <h5>How does {APPLICATION_NAME} compare to charting software?</h5>
        <p>
          Our product  is a market research application that works best in tandem with charting
          software that typically offers scanning engines and bottom-up data visualizations.
          It is not designed to replace those tools. {APPLICATION_NAME} has tools which analyze and combine data for deeper analysis.
          For example, comparing earnings reactions between companies quarter by quarter to determine
          if one company's earnings have predictive ability for shorter-term trading strategies.
        </p>
        <p>
          The <Link to="/tools">Tools</Link> page has more examples.
        </p>
      </section>
      <section>
        <h5>Does {APPLICATION_NAME} offer custom tools?</h5>
        <p>
          Yes, email us at <em>support@themarkettoolkit.com</em> for pricing. Our tools are JavaScript files that
          executed by the application. Adding custom tools is as simple as copying a tool file into a special
          folder on your computer. Also, if you have suggestions for tools we can add to our
          library, feel free to send us your ideas.
        </p>
      </section>
      <section>
        <h5>Can I use outputs, such as tables and charts, in publications?</h5>
        <p>
          Yes, you can without restrictions.
          References are appreciated but not required.
          Tables are exported as CSV files and charts are exported as PNG images.
        </p>
      </section>
    </main >
  );
}