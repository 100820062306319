import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";

export default function ContactUs() {
  return (
    <main className="contact-us">
      <Helmet>
        <title>{APPLICATION_NAME} | Contact Us</title>
        <meta name="description" content="Contact us with questions and suggestions." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/contact-us" />
      </Helmet>
      <header>
        <h1 className="page-title">Contact Us</h1>
      </header>
      <section>
        <p>
          For common questions, check our <NavLink to="/faq">Frequently-Asked Questions</NavLink> page.
        </p>
        <p>
          For other questions, you can reach us at <em>support@themarkettoolkit.com</em>
        </p>
      </section>
    </main>
  );
}