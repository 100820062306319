
export default function CopyButton({ buttonText, copyText, ...props }) {

  const handleClick = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(copyText).then(function () {
      const btn = e.target;
      btn.classList.remove("btn-primary");
      btn.classList.add("btn-success");
      btn.innerText = "Copied"
    });
  }

  return <button type="button" className="btn btn-primary btn-sm" onClick={handleClick} {...props}>{buttonText}</button>
}