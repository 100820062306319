import { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useAuthValue } from "../../AuthContext";
import ExistingLicenses from "../../components/licensing/ExistingLicenses";
import AddLicenses from "../../components/licensing/AddLicenses";
import Subscriptions from "../../components/licensing/Subscriptions"
import Spinner from "../../components/Spinner";
import { apiGetLicenses, apiGetSubscriptions, apiAddLicenses, apiRemoveLicenses, apiSetupCheckout } from "../../apiService";
import { APPLICATION_NAME } from "../../Constants";
import BuyLicenses from "../../components/licensing/BuyLicenses";

export default function Licenses() {

  const { currentUser } = useAuthValue();
  const [licenseData, setLicenseData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateLicenseData = useCallback(async () => {
    if (currentUser !== null) {
      const data = await apiGetLicenses(currentUser.uid);
      setLicenseData(data);
    }
  }, [currentUser]);

  const updateSubscriptionData = useCallback(async () => {
    if (currentUser !== null) {
      const data = await apiGetSubscriptions(currentUser.uid);
      setSubscriptionData(data);
    }
  }, [currentUser]);

  const setupCheckout = async (count) => {
    const result = await apiSetupCheckout(currentUser.uid, currentUser.email, count);
    return result;
  }

  const startLoading = () => {
    setIsLoading(true);
  }

  const endLoading = () => {
    setTimeout(() => setIsLoading(false), 1000);
  }

  const addLicenses = async (count) => {
    startLoading();
    await apiAddLicenses(licenseData.customerId, licenseData.subscriptionId, count);
    await updateLicenseData();
    await updateSubscriptionData();
    endLoading();
  }

  const removeLicense = async (licenseKey) => {
    const confirmed = window.confirm("Are you sure you want to remove this license?");
    if (confirmed) {
      startLoading();
      await apiRemoveLicenses(licenseData.customerId, licenseData.subscriptionId, [licenseKey]);
      await updateLicenseData();
      await updateSubscriptionData();
      endLoading();
    }
  }

  useEffect(() => {
    startLoading();
    updateLicenseData();
    updateSubscriptionData();
    endLoading();
  }, [updateLicenseData, updateSubscriptionData])

  return (
    <main className="licenses">
      <Helmet>
        <title>{APPLICATION_NAME} | Licenses</title>
        <meta name="description" content="View your list of existing licenses." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/licenses" />
      </Helmet>
      {
        isLoading
          ?
          <Spinner />
          :
          <>

            <header>
              <h1 className="page-title">Licenses</h1>
              <p>
                The application is licensed per user.
                After purchasing a license, you can install the application on an unlimited number of devices and use the same key.
                The charge for each license is <strong>$15/month</strong>.
              </p>
              <p>
                <strong>Individuals</strong> - One license.<br />
                <strong>Companies</strong> - One license for each employee that uses the application.
              </p>
            </header>

            <section className="licenses-section">
              <h5 className="mb-4">Subscriptions</h5>
              <Subscriptions subscriptionData={subscriptionData} />
            </section>

            <section className="licenses-section">
              <h5 className="mb-4">Licenses</h5>
              <ExistingLicenses licenseData={licenseData} removeLicense={removeLicense} />
            </section>

            <section className="licenses-section">
              {
                !licenseData?.subscriptionId
                  ? <BuyLicenses setupCheckout={setupCheckout} />
                  : ""
              }
              {
                licenseData?.subscriptionStatus === "active"
                  ? <AddLicenses addLicenses={addLicenses} />
                  : ""
              }
            </section>

          </>
      }
    </main>
  );
}