import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { signInWithEmailAndPassword, sendEmailVerification, AuthErrorCodes } from "firebase/auth";
import { auth } from "../../firebase-config";
import { APPLICATION_NAME } from "../../Constants";
import DisableAfterClickButton from "../../components/DisableAfterClickButton";

export default function SignIn() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate()

  const signIn = async (e) => {
    try {

      e.preventDefault();
      await signInWithEmailAndPassword(auth, email, password);
      setError(null);

      if (!auth.currentUser.emailVerified) {
        await sendEmailVerification(auth.currentUser);
        navigate('/must-verify-email')
      }
      else {
        navigate('/')
      }

    }
    catch (error) {
      switch (error.code) {
        case AuthErrorCodes.USER_DELETED:
          setError("Email address not found.");
          break;
        case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
          setError("Incorrect email address or password.");
          break;
        case AuthErrorCodes.INVALID_PASSWORD:
          setError("Incorrect password.");
          break;
        case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
          setError("Too many attempts. Please reset your pasword or try again later.")
          break;
        default:
          setError(error.message);
      }
    };
  }

  return (
    <main className="sign-in">
      <Helmet>
        <title>{APPLICATION_NAME} | Sign In</title>
        <meta name="description" content="Sign in to the website to access account information." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/sign-in" />
      </Helmet>
      <h1 className="page-title mb-3">Sign In</h1>
      <p className="text-danger">{error ?? ""}</p>
      <form className="mb-3">
        <p className="mb-3 col-6">
          <label htmlFor="email" className="form-label">Email address</label>
          <input type="email" className="form-control" id="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
        </p>
        <p className="mb-3 col-6">
          <label htmlFor="password" className="form-label">Password</label>
          <input type="password" className="form-control" id="password" value={password} required onChange={(e) => setPassword(e.target.value)} />
        </p>
        <DisableAfterClickButton text="Submit" duration="100" handleClick={signIn} />
      </form>
      <p className="mb-1">
        Don't have an account? <Link to='/sign-up'>Sign Up</Link>
      </p>
      <p>
        Forgot Password? <Link to='/reset-password'>Reset Password</Link><br />
      </p>
    </main>
  );
}