import Screenshot from "../components/Screenshot";

export default function BlogContent({ id, full }) {

  const Wrapper = (props) => {
    return (
      <section className="text-muted mb-4">
        {props.children}
      </section>
    )
  };

  switch (id) {
    case "B1":
      return (
        <Wrapper>
          <p>
            Stock market performance has varied widely between presidents and also within each president's terms.
            Below is a table of presidential performance for the last 50 years.
            The tables and charts below were generated using our Presidential Performance (S10) tool.
            All presidents had at least one negative year and at least one year with gains of over 25 percent.
            Negative years were typically followed by strong positive years.
          </p>
          <p>
            Despite year-to-year volatility, the average year's performance was 8.90%.
            In hindsight, buying the dip would have been rewarding.
            Four presidents had average returns of over 10 percent:
            Bill Clinton (15.87%), Donald Trump (14.59%), George H. W. Bush (12.87%), and Barack Obama (12.46%).
            Two of the best performing terms were under republican presidents, two under democratic presidents.
          </p>
          <Screenshot subfolder="blog/B1" name="all.png" caption="Table 1: All Presidents" title="A table of yearly S&P 500 performance for all presidents since 1974." />
          <p>
            Tables 2 and 3 below show the same information by political party.
            Performance under Democratic presidents was higher, with average gains of 9.91% compared to 7.35% under Republican presidents.
            It is interesting that median gains were higher with Republican presidents.
            This is explained by higher volatility.
            Negative years were more muted under Democratic leadership, leading to a closer relationship between mean and median.
          </p>
          <Screenshot subfolder="blog/B1" name="republican.png" caption="Table 2: Republican Presidents" title="A table of yearly S&P 500 performance for Republican presidents since 1974." />
          <Screenshot subfolder="blog/B1" name="democratic.png" caption="Table 3: Democratic Presidents" title="A table of yearly S&P 500 performance for Democratic presidents since 1974." />
        </Wrapper>
      );
    case "B2":
      return (
        <Wrapper>
          <p>
            Stock market performance of the S&P 500 has historically been significantly stronger in the first and third
            years of a president's term, especially the third year, with a mean return of 15.50% over the last 50 years.
            Risk during the third year has been unusually low, with the worst return of -0.73%, under Barrack Obama in 2015.
            Performance tends to alternate, with odd term years producing outsized gains, and even term years spent
            digesting those gains.
          </p>
          <p>
            The second year of a president's term is the weakest, with 8 up years and 5 down years over the last 50 total years.
            Only one second year of a four year term produced returns over 20 percent, with gains of 26.67% under Bill Clinton in 1998.
            The third term year, in contrast, produced four years of returns of over 20 percent and a fifth year of 19.53% in 1999.
          </p>
          <p>
            The first year of president's term has had quite a bit of volatility, with six years of returns over 25% and three years of
            more than a 10% loss. It is notable that overall, all term years produced gains of more than 4% on average.
          </p>
          <p>
            The tables below were generated using our Presidential Cycle (S11) tool.
          </p>
          <Screenshot subfolder="blog/B2" name="presidential_cycle.png" caption="Table 1: The Presidential Cycle" title="A table of year-by-year performance of the S&P 500 during the four-year Presidential Cycle." />
        </Wrapper>
      );
    case "B3":
      return (
        <Wrapper>
          <p>
            The tables below were generated using our Performance Around Holidays (S12) tool.
          </p>
          <Screenshot subfolder="blog/B3" name="median_summary.png" caption="Table 1: Median Performance" title="Table of median performance of the S&P 500 the five days before New Years Day and the five days after." />
          <Screenshot subfolder="blog/B3" name="mean_summary_details.png" caption="Tables 2 and 3: Mean Performance" title="Tables of year-by-year mean performance of the S&P 500 the five days before New Years Day and the five days after." />
        </Wrapper>
      );
    default:
      return (
        <Wrapper>
          <p>Tool description placeholder for Blog {id}...</p>
        </Wrapper>
      );
  }

}