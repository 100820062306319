
export default function DownloadAppLinks() {

  const items = [];
  const add = (os, url) => items.push({ os, url });
  add("Windows", "https://github.com/the-market-toolkit/releases-windows/releases/download/1.0.0/The-Market-Toolkit-Setup-1.0.0.exe");
  add("macOS", "https://github.com/the-market-toolkit/releases-mac/releases/download/v1.0.0/The-Market-Toolkit-1.0.0.dmg");

  return (
    <>
      {
        items.map((item, i) => {
          return (
            <a key={i}
              href={item.url}
              className={"btn btn-primary disabled" + (i > 0 ? " ms-3" : "")}
              download>
              Download {item.os}
            </a>
          )
        })
      }
    </>

  );
}