import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { createUserWithEmailAndPassword, AuthErrorCodes } from "firebase/auth";
import { auth } from "../../firebase-config";
import { APPLICATION_NAME } from "../../Constants";
import DisableAfterClickButton from "../../components/DisableAfterClickButton";

export default function SignUp() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState(null);
  const navigate = useNavigate()

  const signUp = async (e) => {

    e.preventDefault();

    try {

      if (password !== confirmPassword) {
        setError("Passwords do not match.");
        return;
      }

      await createUserWithEmailAndPassword(auth, email, password);
      setError(null);
      navigate('/sign-in')
    }
    catch (error) {
      switch (error.code) {
        case AuthErrorCodes.EMAIL_EXISTS:
          setError("Email address already taken.");
          break;
        case AuthErrorCodes.WEAK_PASSWORD:
          setError("Password must be at least 6 characters.");
          break;
        default:
          setError(error.message);
      }
    };
  }

  return (
    <main className="sign-up">
      <Helmet>
        <title>{APPLICATION_NAME} | Sign Up</title>
        <meta name="description" content="Sign up for an account." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/sign-up" />
      </Helmet>
      <h3 className="mb-3">Sign Up</h3>
      <p className="text-danger">{error ?? ""}</p>
      <p className="text-muted">After signing up, you will be redirected to the Sign In page.</p>
      <form className="mb-3">
        <p className="mb-3 col-6">
          <label htmlFor="email" className="form-label">Email address</label>
          <input type="email" className="form-control" id="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
        </p>
        <p className="mb-3 col-6">
          <label htmlFor="password" className="form-label">Password</label>
          <input type="password" className="form-control" id="password" value={password} required
            onChange={(e) => setPassword(e.target.value)} />
          <span className="form-text">Password must be at least 6 characters.</span>
        </p>
        <p className="mb-3 col-6">
          <label htmlFor="confirm_password" className="form-label">Confirm Password</label>
          <input type="password" className="form-control" id="confirm_password" value={confirmPassword} required
            onChange={(e) => setConfirmPassword(e.target.value)} />
          <span className="form-text">Enter the password a second time.</span>
        </p>
        <DisableAfterClickButton text="Submit" duration="500" handleClick={signUp} />
      </form>
    </main>
  );
}