import { Link, useNavigate } from 'react-router-dom';
import * as dayjs from 'dayjs';

export default function Subscriptions({ subscriptionData }) {

  const navigate = useNavigate()

  if (subscriptionData == null) return "";

  if (subscriptionData.length === 0) {
    return <p>You don't currently have any subscriptions.</p>
  }

  const formatDate = (n) => {
    if (!n) return "";
    const d = dayjs(n * 1000);
    return d.format("MM/DD/YYYY");
  }

  const formatCurrentPeriod = (x) => {
    return `${formatDate(x.currentPeriodStart)} - ${formatDate(x.currentPeriodEnd)}`;

  }

  const formatCard = (x) => {
    return (!x.cardBrand || !x.cardLast4) ? "" : `${x.cardBrand} - ${x.cardLast4}`;
  }

  const finishCheckout = async (e, clientSecret) => {
    e.preventDefault();
    navigate({
      pathname: '/checkout',
      search: `?sec=${clientSecret}`
    });
  };

  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col" className="text-center">Active Licenses</th>
          <th scope="col" className="text-center">Renewing Licenses</th>
          <th scope="col" className="">Status</th>
          <th scope="col" className="">Created</th>
          <th scope="col" className="">Current Period</th>
          <th scope="col" className="">Credit Card</th>
          <th scope="col" className="">Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          subscriptionData?.map((x, index) => {
            return (
              <tr key={index}>
                <td className="text-center">{x.activeLicenseCount}</td>
                <td className="text-center">{x.renewLicenseCount}</td>
                <td className="">{x.status}</td>
                <td className="">{formatDate(x.created)}</td>
                <td className="">{formatCurrentPeriod(x)}</td>
                <td className="">{formatCard(x)}</td>
                <td className="">{
                  x.status === "incomplete"
                    ? <button type="button" className="btn btn-primary btn-sm"
                      onClick={(e) => finishCheckout(e, x.clientSecret)}>Continue Checkout</button>
                    : <Link className='btn btn-primary btn-sm' to={`/update-card?sid=${x.id}`}>Update Card</Link>
                }
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  );
}