import { Outlet } from "react-router-dom";
import { useState, useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { AuthProvider } from './AuthContext'
import { usePageTracking } from "./analyticsTracking";
import { auth } from "./firebase-config";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "./Constants";
import Header from "./components/Header";
import Footer from "./components/Footer";
import './index.css';

export default function App() {

  const [currentUser, setCurrentUser] = useState(null)

  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    })
  }, [])

  usePageTracking();

  return (
    <div>
      <Helmet>
        <title>{APPLICATION_NAME}</title>
        <meta name="description" content="" />
      </Helmet>
      <AuthProvider value={{ currentUser }}>
        <Elements stripe={stripe}>
          <Header />
          <div className="container-lg content">
            <Outlet />
          </div>
          <Footer />
        </Elements>
      </AuthProvider>
    </div>
  );
}