import * as dayjs from 'dayjs';
import CopyButton from '../CopyButton';

export default function ExistingLicenses({ licenseData, removeLicense }) {
  if (licenseData == null) return "";

  if (licenseData.licenses.length === 0) {
    return <p>You don't currently have any licenses.</p>
  }

  const NO_EXP_DATE = "NONE";

  const formatExpires = (expirationDate) => {
    return expirationDate === NO_EXP_DATE
      ? "None - renews monthly"
      : dayjs(expirationDate).format("MM/DD/YYYY");
  }

  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col" className="text-center">License Key</th>
          <th scope="col" className="text-center">Expires</th>
          <th scope="col" className="text-center"></th>
        </tr>
      </thead>
      <tbody>
        {
          licenseData?.licenses.map((license, index) => {
            return (
              <tr key={index}>
                <td className="w-30 text-center license-key">{license.key}</td>
                <td className="w-20 text-center">{formatExpires(license.expirationDate)}</td>
                <td className="w-25 text-end">
                  <CopyButton buttonText="Copy Key" copyText={license.key} />
                  <button type="submit" className="btn btn-danger btn-sm ms-3"
                    disabled={license.expirationDate !== NO_EXP_DATE}
                    onClick={() => removeLicense(license.key)}>Remove</button>
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  );
}