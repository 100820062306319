import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { apiGetBlogArticlesList } from "../apiService";
import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";
import LastUpdated from "../components/LastUpdated";

export default function Blog() {

  const [allBlogArticles, setAllBlogArticles] = useState([]);
  const [blogArticles, setBlogArticles] = useState([]);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    const getData = async () => {
      const _articles = await apiGetBlogArticlesList();
      setAllBlogArticles(_articles);
      setBlogArticles(_articles.slice());
    }
    getData();
  }, []);

  const filter = (e) => {
    const text = e.target.value;
    setFilterText(text)
    if (!text) {
      setBlogArticles(allBlogArticles.slice());
    }
    else {
      const textLowerCase = text.toLowerCase();
      const _articles = allBlogArticles.filter(x => x.title.toLowerCase().includes(textLowerCase));
      setBlogArticles(_articles);
    }
  }

  return (
    <main className="blog">
      <Helmet>
        <title>{APPLICATION_NAME} | Blog</title>
        <meta name="description" content="Our blog articles examine historical trends in specific stocks or the market overall." />
        <link rel="canonical" href="https://www.themarkettoolkit.com/blog" />
      </Helmet>
      <header>
        <h1 className="page-title">Blog</h1>
        <p className="text-muted mb-3">
          Blog articles analyze themes such as the presidential cycle or market performance before and after holidays.
        </p>
      </header>
      <form className="mb-0 col-12">
        <input type="text" className="form-control" placeholder="Enter a keyword or phrase in this text box to filter the list of blog articles."
          value={filterText} onChange={filter} />
      </form>
      {
        blogArticles.map((article, i) => {
          return <article className="col-12" key={i}>
            <h5 id={article.id}>{article.title}</h5>
            <p className="text-muted">
              {article.description}
            </p>
            <p><Link to={`/blog/${article.route}`}>View Blog Post</Link></p>
            <LastUpdated updated={article.updated} />
          </article>
        })
      }
    </main>
  );
}