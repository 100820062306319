import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";
import { apiGetBlogArticlesList } from "../apiService";
import BlogContent from "../components/BlogContent";
import LastUpdated from "../components/LastUpdated";

export default function BlogDetails() {

  const [article, setArticle] = useState(null);
  const { route } = useParams();

  useEffect(() => {
    const getData = async () => {
      const _articles = await apiGetBlogArticlesList();
      const _article = _articles.find(x => x.route === route);
      setArticle(_article);
    }
    getData();
  }, [route])

  return (
    <main className="blog-details">
      <Helmet>
        <title>{`${APPLICATION_NAME} | ${article?.title}`}</title>
        <meta name="description" content={article?.description} />
        <link rel="canonical" href={`https://www.themarkettoolkit.com/blog/${article?.route}`} />
      </Helmet>
      <h1 className="page-title">{article?.title}</h1>
      <BlogContent id={article?.id} full={true} />
      <LastUpdated updated={article?.updated} />
      <p>
        <Link to={`/blog/#${article?.id}`}>Back to List</Link>
      </p>
    </main>
  );
}