import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "../Constants";

export default function UserImports() {

  return (
    <main className="user-imports">
      <Helmet>
        <title>{APPLICATION_NAME} | User Imports</title>
        <meta name="description" content="The UserImports folder contains downloaded CSV files for entering your portfolio positions, trades, and events." />
      </Helmet>
      <header>
        <h1 className="page-title">User Imports</h1>
      </header>

      <section className="mb-5">
        <p>
          The desktop application's <strong>"UserImports"</strong> folder, opened by selecting The Market Toolkit, then Open Folder,
          then User Imports folder from the top menu, contains CSV files that are downloaded along with tools that require them.
          CSV files can be opened by text editors such as Microsoft Notepad or spreadsheet applications such as Microsoft Excel.
          This folder is initially empty.
          Downloaded user imports files contain sample data.
          Descriptions of those files are below.
        </p>
      </section>

      <section className="mb-5">
        <h5>Events.csv</h5>
        <p>
          This file allows custom events to be entered.
          These events are added to charts.
        </p>
        <p>
          Multiple events files are supported.
          To add an additional events file, make sure it's a CSV file (.csv extension) and the file name starts with "Events".
          The additional file will automatically show in the events files list.
        </p>
        <p>Sample content:</p>
        <pre>
          <code>
            symbol,start_date,end_date,label,position{"\n"}
            AAPL,2023-06-08,2023-06-09,AAPL Test,top{"\n"}
            ,2022-01-25,2022-01-26,Fed meetings,bottom{"\n"}
          </code>
        </pre>
        <p>File fields:</p>
        <ul>
          <li><strong>symbol</strong> - The symbol that the event is associated with. Optional.</li>
          <li><strong>start_date</strong> - The event's start date.</li>
          <li><strong>end_date</strong> - The event's end date. For single-day events, start_date and end_date should be the same date.</li>
          <li><strong>label</strong> - The label text to show in the chart. Labels are optional.
            If left blank, the date range will be shaded but no label will be shown.</li>
          <li><strong>position</strong> - The label's position. Options are "top" and "bottom". Optional. The default is "top".</li>
        </ul>
      </section>

      <section className="mb-5">
        <h5>Journal.csv</h5>
        <p>
          This file contains journal entries.
        </p>
        <p>Sample content:</p>
        <pre>
          <code>
            symbol,date,label,entry{"\n"}
            ,2024-01-22,Strong - no trades,The market looks strong. There are no trades to make.{"\n"}
            AAPL,2024-01-04, Wait on AAPL, I'm waiting for an opportunity to buy Apple.{"\n"}
          </code>
        </pre>
        <p>File fields:</p>
        <ul>
          <li>
            <strong>symbol</strong> - The symbol that the journal entry is associated with. Optional.
            If set, a view button will be displayed in that journal entry's row.
            Clicking the button will show a chart for that symbol centered around the journal entry date.
          </li>
          <li><strong>date</strong> - The journal entry date.</li>
          <li><strong>label</strong> - The label text to show in the chart.</li>
          <li><strong>entry</strong> - The journal entry text. HTML is supported.</li>
        </ul>
      </section>

      <section className="mb-5">
        <h5>Portfolio.csv</h5>
        <p>
          This file allows positions to be entered.
        </p>
        <p>
          Multiple portfolios supported.
          To add an additional portfolios file, make sure it's a CSV file (.csv extension) and the file name starts with "Portfolio".
          The additional file will automatically show in the portfolios files list.
        </p>
        <p>Sample content:</p>
        <pre>
          <code>
            symbol,shares,tags{"\n"}
            AAPL,200,tech{"\n"}
            MSFT,100,tech{"\n"}
          </code>
        </pre>
        <p>File fields:</p>
        <ul>
          <li><strong>symbol</strong> - The position's symbol. This can be any symbol that has a price, including ETFs and indexes.</li>
          <li><strong>shares</strong> - The number of shares owned.</li>
          <li><strong>tags</strong> - Any value that is useful for table filtering. Tags are optional.
            For example, "Defensive|Consumer Staples|Value".
            The pipe ("|") is used here as a separator and puts the position into three categories ("Defensive", "Consumer Staples", and "Value").
            The separator is used for readability and isn't strictly needed.
          </li>
        </ul>
      </section>

      {
        /*
        <section className="mb-5">
          <h5>Trades.csv</h5>
          <p>
            This file allows trades to be entered. This file is used by the S34 tool.
          </p>
          <p>Sample content:</p>
          <pre>
            <code>
              symbol,type,shares,entry_date,entry_price,exit_date,exit_price{"\n"}
              AAPL,long,100,2023-03-20,156.97,2023-04-03,165.72{"\n"}
              AAPL,short,100,2023-08-03,190.91,2023-08-15,177.45{"\n"}
              MSFT,long,100,2023-07-27,330.02{"\n"}
            </code>
          </pre>
          <p>File fields:</p>
          <ul>
            <li><strong>symbol</strong> - The position's symbol. This can be any symbol that has a price, including ETFs and indexes.</li>
            <li><strong>type</strong> - The position type. Valid values are "long" and "short".</li>
            <li><strong>shares</strong> - The number of shares owned.</li>
            <li><strong>entry_date</strong> - The date the trade was entered.</li>
            <li><strong>entry_price</strong> - The price the date the trade was entered.</li>
            <li><strong>exit_date</strong> - The date the trade was exited. Leave this blank for open trades. Optional.</li>
            <li><strong>exit_price</strong> - The price on exit_date. Leave this blank for open trades. Optional.</li>
          </ul>
          <p>
            To sell partial positions, add multiple rows.
            Here is an example of buying 100 shares on 6/8/2023, then selling 50 shares on 8/2/2023.
            50 shares remain unsold.
          </p>
          <pre>
            <code>
              symbol,type,shares,entry_date,entry_price,exit_date,exit_price{"\n"}
              AAPL,long,50,2023-06-08,180.00,2023-08-02,192.00{"\n"}
              AAPL,long,50,2023-06-08,180.00{"\n"}
            </code>
          </pre>
        </section>
          */
      }

    </main>
  );
}