import { useEffect } from "react";

export default function Screenshot({ subfolder, name, title, caption, size }) {

  //https://github.com/biati-digital/glightbox
  useEffect(() => {
    window.GLightbox({
      selector: '.glightbox',
    });
  }, []);

  const src = `${process.env.REACT_APP_BASE_URL}/screenshots/${subfolder}/${name}`;

  return (
    <figure>
      {
        caption ?
          <figcaption className="text-muted text-small text-center">
            <cite>{caption}</cite>
          </figcaption>
          : <></>
      }
      <a href={src} className="glightbox">
        <img
          className="screenshot img-fluid"
          src={src}
          alt={title}
          width={size ?? "100%"}
          height={size ?? "100%"} />
      </a>
    </figure>

  );
}